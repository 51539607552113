import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import classNames from "classnames";
import { CheckoutHeader, CheckoutFooter, CheckoutList, CheckoutActionCard, CheckoutCreateAccountForm, SendEnquiryForm, } from "../";
import { CheckoutLoginForm } from "../login/CheckoutLoginForm";
import { useAppGuardian } from "@bundles/UIAppBundle/services";
import { useTranslate } from "@bluelibs/x-ui-i18n-bundle";
const checkoutStepEnum = {
    register: CheckoutCreateAccountForm,
    "send-enquiry": SendEnquiryForm,
    login: CheckoutLoginForm,
};
const CheckoutMainLayout = ({ items, logo, onSendInquiry, changeQuantity, onLogin, onRegister, shouldClosePageOnBack, }) => {
    var _a;
    const t = useTranslate("components.checkout.layout");
    const guardian = useAppGuardian();
    const [activeStep, setActiveStep] = useState("main");
    const layoutClasses = classNames("cc-checkout-layout__container", {
        "cc--grid": activeStep !== "main",
    });
    const layoutCardClasses = classNames({
        "cc-col-4": activeStep !== "main",
        "cc-checkout-layout__card": activeStep === "main",
    });
    const noItems = items.length === 0;
    const onGoToRegister = () => setActiveStep("register");
    const onGoToLogin = () => setActiveStep("login");
    const setSendEnquiry = () => {
        setActiveStep("send-enquiry");
    };
    const onBack = () => {
        if (shouldClosePageOnBack) {
            window.close();
            return;
        }
        if (activeStep === "main") {
            window.location.href = "/";
        }
        if (activeStep !== "main") {
            setActiveStep("main");
        }
    };
    const toggleChat = () => {
        window.Tawk_API.toggle();
    };
    const ActiveComponent = activeStep && checkoutStepEnum.hasOwnProperty(activeStep)
        ? checkoutStepEnum[activeStep]
        : undefined;
    const login = async (data) => {
        await onLogin(data);
        setActiveStep("send-enquiry");
    };
    return (_jsxs("main", { children: [_jsx(CheckoutHeader, { logo: logo, onBack: onBack }, void 0), _jsxs("div", Object.assign({ style: { display: "flex", flexDirection: "column" } }, { children: [_jsxs("div", Object.assign({ className: layoutClasses }, { children: [_jsxs("div", Object.assign({ className: layoutCardClasses }, { children: [_jsx("h4", Object.assign({ className: "cc-checkout-layout__heading" }, { children: t("yourWishlist") }), void 0), noItems ? (_jsx("div", Object.assign({ style: {
                                            width: "100%",
                                            height: "199px",
                                            background: "#EDEDED 0% 0% no-repeat padding-box",
                                            borderRadius: "10px",
                                            opacity: "1",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        } }, { children: _jsx("h4", Object.assign({ style: {
                                                textAlign: "center",
                                                font: "normal normal normal 24px/33px Open Sans",
                                            }, className: "cc-checkout-layout" }, { children: t("youDoNotHaveItemsInTheWishlist") }), void 0) }), void 0)) : (_jsx(CheckoutList, { items: items, changeQuantity: changeQuantity, type: activeStep !== "main" ? "vertical" : "horizontal" }, void 0))] }), void 0), activeStep === "main" && (_jsxs("div", Object.assign({ className: "cc-checkout-layout__action-list" }, { children: [noItems ? null : (_jsx("div", Object.assign({ className: "cc-checkout-layout__action-item" }, { children: _jsx(CheckoutActionCard, { text: t("actionCards.receiveQuoteText"), buttonText: t("actionCards.receiveQuoteBtn"), onClick: setSendEnquiry }, void 0) }), void 0)), !guardian.state.isLoggedIn && (_jsx("div", Object.assign({ className: "cc-checkout-layout__action-item" }, { children: _jsx(CheckoutActionCard, { text: t("actionCards.createAccountText"), buttonText: t("actionCards.createAccountBtn"), onClick: onGoToRegister, theme: "light" }, void 0) }), void 0))] }), void 0)), ActiveComponent && (_jsx("div", Object.assign({ className: "cc-offset-1 cc-col-7" }, { children: _jsx(ActiveComponent, Object.assign({ onSubmit: onSendInquiry, defaultValues: (_a = guardian.state.user) === null || _a === void 0 ? void 0 : _a.endUser, type: activeStep }, {
                                    onGoToLogin,
                                    onGoToRegister,
                                    onLogin: login,
                                    onRegister,
                                }), void 0) }), void 0))] }), void 0), _jsx(CheckoutFooter, { onClick: toggleChat }, void 0)] }), void 0)] }, void 0));
};
export default CheckoutMainLayout;
