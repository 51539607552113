import { useUISession } from "@bluelibs/x-ui-session-bundle";
export const useRouting = () => {
    const uiSession = useUISession();
    const currentLocale = uiSession.get("currentLocale");
    const openNewPage = (link) => {
        const url = new URL(link);
        if (currentLocale) {
            url.pathname = `/${currentLocale}${url.pathname}`;
        }
        window.open(url.href, "_blank");
    };
    return { openNewPage };
};
