import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState } from "react";
import { Input } from "../form";
import { Button } from "../button";
import { useTranslate } from "@bluelibs/x-ui-i18n-bundle";
export const ChatSettings = ({ onSubmit, onClose, currentSettings, }) => {
    const t = useTranslate("components.chat.login");
    const [isConnecting, setIsConnecting] = useState(false);
    const firstName = useRef();
    const lastName = useRef();
    const handleSubmit = (e) => {
        e.preventDefault();
        if (firstName.current.value && lastName.current.value) {
            setIsConnecting(true);
            onSubmit(firstName.current.value, lastName.current.value);
        }
    };
    return (_jsxs("form", Object.assign({ onSubmit: handleSubmit, className: "cc-chat-login-form" }, { children: [_jsx("p", Object.assign({ className: "header" }, { children: t("settingsHeader") }), void 0), _jsx(Input, { ref: firstName, defaultValue: currentSettings.firstName, className: "input", placeholder: t("form.firstName.placeholder") }, void 0), _jsx(Input, { ref: lastName, defaultValue: currentSettings.lastName, className: "input", placeholder: t("form.lastName.placeholder") }, void 0), _jsx(Button, Object.assign({ disabled: isConnecting, className: "button", type: "submit", bgColor: "primary", color: "white" }, { children: t("confirm") }), void 0), _jsx(Button, Object.assign({ disabled: isConnecting, onClick: onClose, className: "button-skip", bgColor: "custom", color: "gray" }, { children: t("close") }), void 0)] }), void 0));
};
