import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Button } from "antd";
import Portal from "../portal/Portal";
import { IconChatSettings, IconClose } from "../svg/icons";
import { useTranslate } from "@bluelibs/x-ui-i18n-bundle";
const Sidebar = ({ size = "normal", title, isAvailable, wrapperPadding = true, onClose, children, isMobile, isForChat, toggleChatSettings, }) => {
    const t = useTranslate("components.sidebar");
    return (_jsx(Portal, { children: _jsxs("div", Object.assign({ style: isMobile ? { width: "100vw", zIndex: "100" } : {}, className: `cc-project-sidebar-w-${size}` }, { children: [_jsxs("div", Object.assign({ className: "cc--flex cc-project-sidebar__header" }, { children: [_jsx("div", Object.assign({ className: "cc-project-sidebar__heading cc--text-bold" }, { children: _jsxs("p", { children: [isForChat && (_jsx("div", { className: `cc-project-sidebar-dot ${isAvailable ? "green" : "red"}` }, void 0)), title] }, void 0) }), void 0), _jsxs("div", Object.assign({ className: "cc-project-sidebar--group" }, { children: [toggleChatSettings ? (_jsx(Button, Object.assign({ className: "cc-button cc--filled cc--primary cc--bg-white cc-project-sidebar__close-btn", onClick: toggleChatSettings }, { children: _jsx("span", { children: _jsx(IconChatSettings, { height: "24px", width: "24px" }, void 0) }, void 0) }), void 0)) : null, _jsx(Button, Object.assign({ className: "cc-button cc--filled cc--primary cc--bg-white cc-project-sidebar__close-btn", onClick: onClose }, { children: _jsx("span", { children: _jsx(IconClose, {}, void 0) }, void 0) }), void 0)] }), void 0)] }), void 0), _jsx("div", Object.assign({ className: `cc-project-sidebar__wrapper cc-project-sidebar__${wrapperPadding ? "p" : "np"}` }, { children: children }), void 0)] }), void 0) }, void 0));
};
export default Sidebar;
