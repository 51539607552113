import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import { ShopSidebar } from "../sidebar";
import { ShopInfoComponent } from "../shop-info";
import { useTranslate } from "@bluelibs/x-ui-i18n-bundle";
import classNames from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "@bundles/UIAppBundle/components/loader/Loader";
import { useUISession } from "@bluelibs/x-ui-session-bundle";
export const ShopProductsComponent = ({ products, districts, productCategories, tags, shopInfo, isLoadingProducts, hasMore, fetchMoreProducts, }) => {
    const t = useTranslate("components.shopProducts");
    const uiSession = useUISession();
    const lang = uiSession.get("currentLocale");
    return (_jsxs("div", Object.assign({ className: styles.wrapper }, { children: [_jsx(ShopSidebar, { withSearch: true, districts: districts, productCategories: productCategories, tags: tags }, void 0), _jsxs("div", Object.assign({ className: styles["main-wrapper"] }, { children: [products.length === 0 && (_jsx("div", Object.assign({ className: styles["no-products"] }, { children: t("noProducts") }), void 0)), products.length > 0 && (_jsx(InfiniteScroll, Object.assign({ scrollThreshold: 0.5, dataLength: products.length, next: fetchMoreProducts, hasMore: hasMore, loader: _jsx("div", Object.assign({ style: {
                                display: "flex",
                                justifyContent: "center",
                                width: "100%",
                            } }, { children: _jsx(Loader, {}, void 0) }), void 0) }, { children: _jsxs("div", Object.assign({ className: styles["product-grid"] }, { children: [isLoadingProducts && (_jsx("div", Object.assign({ className: styles.loader }, { children: _jsx("div", { className: classNames("cc-loader", styles["loader-custom"]) }, void 0) }), void 0)), products.map((product, index) => {
                                    const price = product.pricesEur.length === 1
                                        ? `€${product.pricesEur[0]}`
                                        : `€${Math.min(...product.pricesEur)} - €${Math.max(...product.pricesEur)}`;
                                    return (_jsxs(Link, Object.assign({ to: `/${lang}/${(shopInfo === null || shopInfo === void 0 ? void 0 : shopInfo.linkName) || product.shopLinkName}/product/${product.slug}`, className: styles["product-card"] }, { children: [_jsxs("div", Object.assign({ className: styles.imageWrapper }, { children: [_jsx("img", { src: product.imageUrl, alt: product.name.text }, void 0), product.isComingSoon && (_jsx("div", Object.assign({ className: styles.comingSoonBanner }, { children: t("comingSoon") }), void 0))] }), void 0), _jsxs("div", Object.assign({ className: styles.details }, { children: [_jsx("div", Object.assign({ className: styles.name }, { children: product.name.text }), void 0), _jsx("div", Object.assign({ className: styles.description }, { children: product.description.text }), void 0)] }), void 0), _jsx("div", Object.assign({ className: styles.price }, { children: price }), void 0)] }), index));
                                })] }), void 0) }), void 0)), _jsx(ShopInfoComponent, { shopInfo: shopInfo }, void 0)] }), void 0)] }), void 0));
};
