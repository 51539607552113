import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AssetMediaRenderType, AssetMediaTypeFor3D, } from "@root/api.types";
import { EMPTY_STRING_FOR_STATIC_MARKUP_RENDER } from "../pages/Project/constants";
const HighlightComponent = (props) => (_jsx("a-entity", Object.assign({ class: "highlight-component", visible: "false" }, props, { children: _jsx("a-plane", Object.assign({ animation: "property: color; type: color; to: #f9c74f; dur: 1000; dir: alternate; loop: true;", color: "#1e47c1", position: "0 0 0.05" }, { children: _jsx("a-text", { scale: "0.8 0.8 0.8", value: "Place here", align: "center", color: "#FFF" }, void 0) }), void 0) }), void 0));
export const AssetEntities = ({ project, isForWorld = false, assetService, projectSmart, }) => {
    return (_jsxs("a-entity", Object.assign({ id: "asset-entities" }, { children: [_jsx("a-gltf-model", { id: "world-entity", src: "#world" }, void 0), project.world.meshFile ? (_jsx("a-gltf-model", { "nav-mesh": EMPTY_STRING_FOR_STATIC_MARKUP_RENDER, id: "nav-mesh-entity", src: "#nav-mesh", class: "navmesh", visible: false }, void 0)) : null, project.assetInfos.map((assetInfo) => {
                var _a;
                if (!projectSmart.shouldShowAsset(assetInfo)) {
                    return;
                }
                const { id, media, mediaType, typeFor3D, position, scale, rotation } = projectSmart.getAssetPlacementInfo(assetInfo);
                // combination of projectAssetInfoId and downloadUrl make the itmes unique
                const deduceKey = (mediaFile) => {
                    let res = id.toString();
                    if (Array.isArray(mediaFile)) {
                        res += mediaFile.reduce((prev, file) => prev + file.downloadUrl, "");
                    }
                    else {
                        res += mediaFile.downloadUrl;
                    }
                    return res;
                };
                if (mediaType === AssetMediaRenderType.IMAGE_2D) {
                    return (_jsxs("a-entity", Object.assign({ id: `${id}-entity`, position: position, scale: scale, rotation: rotation }, { children: [_jsx("a-image", { class: "clickable", "fit-texture": true, id: `${id}-image`, src: `#${id}` }, void 0), _jsx(HighlightComponent, {}, void 0)] }), deduceKey(media.image2dFiles[0])));
                }
                if (mediaType === AssetMediaRenderType.IMAGE_360) {
                    return (_jsxs("a-entity", Object.assign({ id: `${id}-entity`, position: position, scale: scale, rotation: rotation }, { children: [_jsx("a-image", { class: "clickable", "fit-texture": true, id: `${id}-image`, src: `#${id}` }, void 0), _jsx(HighlightComponent, {}, void 0)] }), deduceKey(media.image360Files[0])));
                }
                if (mediaType === AssetMediaRenderType.IMAGE_360_CAROUSEL) {
                    return (_jsxs("a-entity", Object.assign({ id: `${id}-entity`, position: position, scale: scale, rotation: rotation }, { children: [_jsx(HighlightComponent, {}, void 0), _jsx("a-image", { class: "clickable", "carousel-2d": `index: 0`, src: `#${id}-0`, id: `${id}-image` }, void 0)] }), deduceKey(media.image360Files)));
                }
                if (mediaType === AssetMediaRenderType.IMAGE_2D_CAROUSEL) {
                    return (_jsxs("a-entity", Object.assign({ id: `${id}-entity`, position: position, scale: scale, rotation: rotation }, { children: [_jsx(HighlightComponent, {}, void 0), _jsx("a-image", { "fit-texture": true, class: "clickable", "carousel-2d": `index: 0`, id: `${id}-image`, src: `#${id}-0` }, void 0)] }), deduceKey(media.image2dFiles)));
                }
                if (mediaType === AssetMediaRenderType.ITEM_3D) {
                    if (typeFor3D === AssetMediaTypeFor3D.OBJ_MTL) {
                        return (_jsx("a-entity", Object.assign({ id: `${id}-entity`, class: "clickable", "obj-model": `obj: #${id}-obj; mtl: #${id}-mtl`, position: position, scale: scale, rotation: rotation }, { children: _jsx(HighlightComponent, {}, void 0) }), deduceKey({
                            downloadUrl: media.image3dOBJ.downloadUrl + media.image3dMTL.downloadUrl,
                        })));
                    }
                    if (typeFor3D === AssetMediaTypeFor3D.GLB) {
                        return (_jsx("a-entity", Object.assign({ id: `${id}-entity`, class: "clickable", "gltf-model": `url(${media.image3DGLB.downloadUrl})`, position: position, scale: scale, rotation: rotation }, { children: _jsx(HighlightComponent, {}, void 0) }), deduceKey(media.image3DGLB)));
                    }
                }
                if (mediaType === AssetMediaRenderType.VIDEO) {
                    const mediaUrl = media.videoUrl || ((_a = media.videoUpload) === null || _a === void 0 ? void 0 : _a.downloadUrl);
                    if (!mediaUrl) {
                        return;
                    }
                    const isVideoUrlDownloadable = (url) => {
                        try {
                            const objUrl = new URL(url);
                            const undownloadableUrls = ["youtube.com", "vimeo.com"];
                            return undownloadableUrls.some((u) => objUrl.hostname.includes(u));
                        }
                        catch {
                            return false;
                        }
                    };
                    return (_jsxs("a-entity", Object.assign({ id: `${id}-entity`, position: position, scale: scale, rotation: rotation }, { children: [_jsx("a-video", { class: "clickable", id: `${id}-video`, src: `#${id}` }, void 0), _jsx(HighlightComponent, {}, void 0)] }), deduceKey({ downloadUrl: mediaUrl })));
                }
            })] }), void 0));
};
