import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSmart } from "@bluelibs/smart";
import { use, useEventManager } from "@bluelibs/x-ui-react-bundle";
import { ProjectInteractions, AssetItems, AssetEntities, } from "@bundles/UIAppBundle/components";
import { AssetClickedEvent } from "@bundles/UIAppBundle/events/AssetClicked.event";
import { AssetService } from "@bundles/UIAppBundle/services";
import { ProjectSmart } from "@bundles/UIAppBundle/smarts/Project.smart";
import { useState, useEffect, Fragment } from "react";
export const AFrameVRComponentMerchants = () => {
    const projectSmart = useSmart(ProjectSmart);
    const assetService = use(AssetService);
    const state = projectSmart.state;
    const { project } = state;
    const { merchantDefaultVideoCoordinates, startCoordinates } = project.world;
    const [loaded, setLoaded] = useState(false);
    const eventManager = useEventManager();
    const setupAssetsListeners = () => {
        const assets = document.querySelectorAll("[id*=-entity]");
        for (const asset of assets) {
            const id = asset.getAttribute("id");
            const projectAssetInfoId = id.substring(0, id.indexOf("-"));
            if (projectAssetInfoId === "world") {
                continue;
            }
            const onClick = () => {
                eventManager.emit(new AssetClickedEvent({
                    projectAssetInfoId,
                    elementId: id,
                }));
            };
            if (asset._onClick) {
                asset.removeEventListener("click", asset._onClick);
            }
            asset._onClick = onClick;
            asset.addEventListener("click", onClick);
        }
        return () => {
            for (const asset of assets) {
                if (asset._onClick) {
                    asset.removeEventListener("click", asset._onClick);
                    delete asset._onClick;
                }
            }
        };
    };
    useEffect(() => {
        if (!loaded)
            return;
        const cleanupAssets = setupAssetsListeners();
        return cleanupAssets;
    }, [loaded, project === null || project === void 0 ? void 0 : project.assetInfos]);
    useEffect(() => {
        const scene = document.querySelector("a-scene");
        const onLoad = () => {
            projectSmart.sendMessageToParent({
                type: "initialised",
            });
            setLoaded(true);
        };
        if (scene.hasLoaded) {
            onLoad();
        }
        else {
            scene.addEventListener("loaded", onLoad);
        }
    }, []);
    const controls = ["gamepad", "keyboard", "checkpoint"];
    const controlsString = controls.join(",");
    return (_jsxs(Fragment, { children: [_jsx(ProjectInteractions, {}, void 0), _jsxs("a-scene", Object.assign({ id: "scene" }, { children: [_jsx("a-sky", { id: "menu-sky", color: "#122f64", radius: "30", visible: "false" }, void 0), _jsx(AssetItems, Object.assign({}, { project }), void 0), _jsx("a-entity", Object.assign({ id: "image-360-wrapper" }, { children: _jsx("a-sky", { visible: "false", id: "image-360", radius: "10", src: "" }, void 0) }), void 0), _jsx("a-entity", Object.assign({ id: "rig", "movement-controls": `constrainToNavMesh: true; speed: 0.35; controls: ${controlsString};`, position: state.isFromMerchantsHeader
                            ? merchantDefaultVideoCoordinates.position
                            : startCoordinates.position, rotation: state.isFromMerchantsHeader
                            ? merchantDefaultVideoCoordinates.rotation
                            : startCoordinates.rotation }, { children: _jsx("a-entity", Object.assign({ id: "player", position: "0 1.6 0", camera: "active: true", "look-controls": "enabled: true" }, { children: _jsx("a-cursor", {}, void 0) }), void 0) }), void 0), _jsx(AssetEntities, Object.assign({}, { project, projectSmart, assetService }), void 0)] }), void 0)] }, void 0));
};
