import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSmart } from "@bluelibs/smart";
import { ChatSmart } from "@bundles/UIAppBundle/smarts/Chat.smart";
import React, { useRef, useEffect, useState } from "react";
import classNames from "classnames";
import { Message } from "../message/Message";
import Sidebar from "../sidebar/Sidebar";
import { useAppGuardian } from "@bundles/UIAppBundle/services";
import { IconChatSend } from "../svg/icons";
import { ChatLogin } from "./ChatLogin";
import { useTranslate } from "@bluelibs/x-ui-i18n-bundle";
import Loader from "../loader/Loader";
import { ProjectSmart } from "@bundles/UIAppBundle/smarts/Project.smart";
import { useUISession } from "@bluelibs/x-ui-session-bundle";
import { ObjectId } from "@bluelibs/ejson";
import { ChatSettings } from "./ChatSettings";
export const ChatSidebar = ({ isOpen, onClose, }) => {
    const t = useTranslate("components.chat");
    const guardian = useAppGuardian();
    const projectSmart = useSmart(ProjectSmart);
    const uiSession = useUISession();
    const _projectId = uiSession.get("projectId");
    const projectId = _projectId ? new ObjectId(_projectId) : null;
    const chats = uiSession.get("chats");
    const { state: projectState } = projectSmart;
    const chatSmart = useSmart(ChatSmart);
    const { state } = chatSmart;
    const messageInputRef = useRef();
    const messageRef = useRef();
    const onSendMessage = async (e) => {
        e.preventDefault();
        if (messageInputRef.current.value) {
            await chatSmart.sendMessage(messageInputRef.current.value);
            messageInputRef.current.focus();
            messageInputRef.current.value = "";
        }
    };
    const onKeyDown = (e) => {
        if (e.key !== "Enter") {
            return;
        }
        onSendMessage(e);
    };
    const onLogin = async (firstName, lastName) => {
        if (firstName && lastName) {
            chatSmart.connect(projectId, firstName, lastName);
        }
        else {
            chatSmart.connect(projectId, "Anonymous", "User");
        }
    };
    const toggleChatSettings = () => {
        chatSmart.updateState({
            isSettingsOpen: !state.isSettingsOpen,
        });
    };
    const onCloseChatSettings = () => {
        chatSmart.updateState({
            isSettingsOpen: false,
        });
    };
    const onUpdateChatSettings = async (firstName, lastName) => {
        await chatSmart.updateChatSettings(firstName, lastName);
        onCloseChatSettings();
    };
    useEffect(() => {
        var _a;
        (_a = messageRef === null || messageRef === void 0 ? void 0 : messageRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo(0, messageRef.current.scrollHeight);
    }, [state.messages]);
    useEffect(() => {
        if (!guardian.state.initialised ||
            chatSmart.state.isConnecting ||
            chatSmart.state.connectedToChat)
            return;
        if (guardian.state.isLoggedIn)
            return;
        if (chats[_projectId]) {
            chatSmart.connect(projectId);
        }
    }, [guardian.state.isLoggedIn]);
    useEffect(() => {
        var _a;
        if (state.connectedToChat) {
            (_a = messageRef === null || messageRef === void 0 ? void 0 : messageRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo(0, messageRef.current.scrollHeight);
        }
    }, [state.connectedToChat]);
    const [width, setWidth] = useState(window.innerWidth);
    const [sideBarSize, setSideBarSize] = useState("lg");
    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [width]);
    useEffect(() => {
        if (width <= 650) {
            setSideBarSize("smallShort");
            return;
        }
        if (width <= 1150) {
            setSideBarSize("normalShort");
            return;
        }
        if (width <= 1150) {
            setSideBarSize("normalShort");
            return;
        }
        if (width <= 1500) {
            setSideBarSize("normal");
            return;
        }
        setSideBarSize("lg");
    }, [width]);
    if (!isOpen)
        return null;
    return (_jsx("div", Object.assign({ className: classNames("cc-chat") }, { children: _jsx(Sidebar, Object.assign({ isForChat: true, isMobile: AFRAME.utils.device.isMobile(), isAvailable: projectState.project.merchant.isAvailableToChat, size: sideBarSize, wrapperPadding: false, title: projectState.project.name, onClose: onClose, toggleChatSettings: toggleChatSettings }, { children: state.isConnecting ? (_jsx(Loader, { centerInScreen: true }, void 0)) : state.connectedToChat ? (_jsxs("div", Object.assign({ className: classNames("cc-chat-container") }, { children: [state.isSettingsOpen && (_jsx("div", Object.assign({ className: "cc-chat-settings" }, { children: _jsx(ChatSettings, { currentSettings: chatSmart.chatSettings, onClose: onCloseChatSettings, onSubmit: onUpdateChatSettings }, void 0) }), void 0)), _jsx("div", Object.assign({ ref: messageRef, className: classNames("cc-chat-messages") }, { children: state.messages.map((message, idx) => (_createElement(Message, { isOwner: false, ...message, key: idx }))) }), void 0), _jsx("form", Object.assign({ onSubmit: onSendMessage, className: classNames("cc-chat-textfield-form") }, { children: _jsxs("div", Object.assign({ className: classNames("cc-chat-textfield") }, { children: [_jsx("textarea", { maxLength: 128, onKeyDown: onKeyDown, ref: messageInputRef, placeholder: t("form.message.placeholder"), className: classNames("cc-chat-textarea") }, void 0), _jsx("button", Object.assign({ disabled: state.isConnecting, type: "submit", className: "cc-chat-button" }, { children: _jsx(IconChatSend, { height: "24px", width: "24px" }, void 0) }), void 0)] }), void 0) }), void 0)] }), void 0)) : (_jsx(ChatLogin, { onSubmit: onLogin }, void 0)) }), void 0) }), void 0));
};
