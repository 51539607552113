export var AcceptedLanguage;
(function (AcceptedLanguage) {
    AcceptedLanguage["de"] = "de";
    AcceptedLanguage["en"] = "en";
})(AcceptedLanguage || (AcceptedLanguage = {}));
export var AnalyticType;
(function (AnalyticType) {
    AnalyticType["CHAT_WITH_MERCHANT"] = "CHAT_WITH_MERCHANT";
    AnalyticType["JOIN_PROJECT"] = "JOIN_PROJECT";
    AnalyticType["REQUEST_DETAILS_ABOUT_PRODUCT"] = "REQUEST_DETAILS_ABOUT_PRODUCT";
    AnalyticType["SEND_EMAIL"] = "SEND_EMAIL";
})(AnalyticType || (AnalyticType = {}));
export var AssetActionType;
(function (AssetActionType) {
    AssetActionType["ADD_TO_BASKET"] = "ADD_TO_BASKET";
    AssetActionType["CHAT"] = "CHAT";
    AssetActionType["EXIT"] = "EXIT";
    AssetActionType["NEXT_ROOM"] = "NEXT_ROOM";
})(AssetActionType || (AssetActionType = {}));
export var AssetMedia3DFileType;
(function (AssetMedia3DFileType) {
    AssetMedia3DFileType["GLB"] = "GLB";
    AssetMedia3DFileType["MTL"] = "MTL";
    AssetMedia3DFileType["OBJ"] = "OBJ";
})(AssetMedia3DFileType || (AssetMedia3DFileType = {}));
export var AssetMediaRenderType;
(function (AssetMediaRenderType) {
    AssetMediaRenderType["IMAGE_2D"] = "IMAGE_2D";
    AssetMediaRenderType["IMAGE_2D_CAROUSEL"] = "IMAGE_2D_CAROUSEL";
    AssetMediaRenderType["IMAGE_360"] = "IMAGE_360";
    AssetMediaRenderType["IMAGE_360_CAROUSEL"] = "IMAGE_360_CAROUSEL";
    AssetMediaRenderType["ITEM_3D"] = "ITEM_3D";
    AssetMediaRenderType["VIDEO"] = "VIDEO";
})(AssetMediaRenderType || (AssetMediaRenderType = {}));
export var AssetMediaTypeFor3D;
(function (AssetMediaTypeFor3D) {
    AssetMediaTypeFor3D["GLB"] = "GLB";
    AssetMediaTypeFor3D["OBJ_MTL"] = "OBJ_MTL";
})(AssetMediaTypeFor3D || (AssetMediaTypeFor3D = {}));
export var AssetMediaTypeForVideo;
(function (AssetMediaTypeForVideo) {
    AssetMediaTypeForVideo["LINK"] = "LINK";
    AssetMediaTypeForVideo["UPLOAD"] = "UPLOAD";
})(AssetMediaTypeForVideo || (AssetMediaTypeForVideo = {}));
export var BasketStatus;
(function (BasketStatus) {
    BasketStatus["ACTIVE"] = "ACTIVE";
    BasketStatus["INACTIVE"] = "INACTIVE";
})(BasketStatus || (BasketStatus = {}));
export var DemoMerchantStatus;
(function (DemoMerchantStatus) {
    DemoMerchantStatus["ACTIVE"] = "ACTIVE";
    DemoMerchantStatus["INACTIVE"] = "INACTIVE";
})(DemoMerchantStatus || (DemoMerchantStatus = {}));
export var DemoProductInventoryStatus;
(function (DemoProductInventoryStatus) {
    DemoProductInventoryStatus["IN_STOCK"] = "IN_STOCK";
    DemoProductInventoryStatus["OUT_OF_STOCK"] = "OUT_OF_STOCK";
})(DemoProductInventoryStatus || (DemoProductInventoryStatus = {}));
export var DemoProjectState;
(function (DemoProjectState) {
    DemoProjectState["PUBLISHED"] = "PUBLISHED";
    DemoProjectState["UNPUBLISHED"] = "UNPUBLISHED";
})(DemoProjectState || (DemoProjectState = {}));
export var DemoUserActiveNotification;
(function (DemoUserActiveNotification) {
    DemoUserActiveNotification["ENQUIRY"] = "ENQUIRY";
    DemoUserActiveNotification["NEW_MESSAGE"] = "NEW_MESSAGE";
    DemoUserActiveNotification["SOMEONE_SPEAKS"] = "SOMEONE_SPEAKS";
    DemoUserActiveNotification["SYSTEM_UPDATE"] = "SYSTEM_UPDATE";
})(DemoUserActiveNotification || (DemoUserActiveNotification = {}));
export var DemoUserRole;
(function (DemoUserRole) {
    DemoUserRole["ADMIN"] = "ADMIN";
    DemoUserRole["ENDUSER"] = "ENDUSER";
    DemoUserRole["MERCHANT"] = "MERCHANT";
    DemoUserRole["WORLD_ADMIN"] = "WORLD_ADMIN";
})(DemoUserRole || (DemoUserRole = {}));
export var DisputeStatus;
(function (DisputeStatus) {
    DisputeStatus["IN_PROGRESS"] = "IN_PROGRESS";
    DisputeStatus["OPEN"] = "OPEN";
    DisputeStatus["SOLVED"] = "SOLVED";
})(DisputeStatus || (DisputeStatus = {}));
export var EndUsersJoinChatEventType;
(function (EndUsersJoinChatEventType) {
    EndUsersJoinChatEventType["CONNECTION_SUCCESSFULL"] = "CONNECTION_SUCCESSFULL";
    EndUsersJoinChatEventType["MESSAGE_DELETED"] = "MESSAGE_DELETED";
    EndUsersJoinChatEventType["MESSAGE_SENT"] = "MESSAGE_SENT";
})(EndUsersJoinChatEventType || (EndUsersJoinChatEventType = {}));
export var MediaType;
(function (MediaType) {
    MediaType["IMAGE_2D"] = "IMAGE_2D";
    MediaType["IMAGE_360"] = "IMAGE_360";
    MediaType["ITEM_3D"] = "ITEM_3D";
    MediaType["VIDEO"] = "VIDEO";
})(MediaType || (MediaType = {}));
export var MerchantIncomingChannel;
(function (MerchantIncomingChannel) {
    MerchantIncomingChannel["NEW"] = "NEW";
})(MerchantIncomingChannel || (MerchantIncomingChannel = {}));
export var MerchantStatus;
(function (MerchantStatus) {
    MerchantStatus["ACTIVE"] = "ACTIVE";
    MerchantStatus["INACTIVE"] = "INACTIVE";
})(MerchantStatus || (MerchantStatus = {}));
export var MerchantsBillingFeature;
(function (MerchantsBillingFeature) {
    MerchantsBillingFeature["ADD_3D_OBJECT"] = "ADD_3D_OBJECT";
    MerchantsBillingFeature["ADD_10_3D_OBJECTS"] = "ADD_10_3D_OBJECTS";
    MerchantsBillingFeature["ADD_360_IMAGE"] = "ADD_360_IMAGE";
    MerchantsBillingFeature["ADD_VIDEO"] = "ADD_VIDEO";
    MerchantsBillingFeature["API_CALL_ACCESS"] = "API_CALL_ACCESS";
    MerchantsBillingFeature["CLIENTS_CAN_GET_CONTACTS"] = "CLIENTS_CAN_GET_CONTACTS";
    MerchantsBillingFeature["LOAD_1_PHOTO"] = "LOAD_1_PHOTO";
    MerchantsBillingFeature["LOAD_10_PHOTOS"] = "LOAD_10_PHOTOS";
    MerchantsBillingFeature["TEXTCHAT"] = "TEXTCHAT";
    MerchantsBillingFeature["VIDEOCHAT"] = "VIDEOCHAT";
    MerchantsBillingFeature["VOICECHAT"] = "VOICECHAT";
    MerchantsBillingFeature["WISHLIST_FOR_CLIENTS"] = "WISHLIST_FOR_CLIENTS";
})(MerchantsBillingFeature || (MerchantsBillingFeature = {}));
export var MerchantsBillingPackageType;
(function (MerchantsBillingPackageType) {
    MerchantsBillingPackageType["BRONZE"] = "BRONZE";
    MerchantsBillingPackageType["GOLD"] = "GOLD";
    MerchantsBillingPackageType["SILVER"] = "SILVER";
    MerchantsBillingPackageType["TITAN"] = "TITAN";
})(MerchantsBillingPackageType || (MerchantsBillingPackageType = {}));
export var MerchantsBillingPeriodType;
(function (MerchantsBillingPeriodType) {
    MerchantsBillingPeriodType["MONTHLY"] = "MONTHLY";
    MerchantsBillingPeriodType["YEARLY"] = "YEARLY";
})(MerchantsBillingPeriodType || (MerchantsBillingPeriodType = {}));
export var MerchantsBillingStatus;
(function (MerchantsBillingStatus) {
    MerchantsBillingStatus["ACTIVE"] = "ACTIVE";
    MerchantsBillingStatus["CANCELED"] = "CANCELED";
    MerchantsBillingStatus["PAYMENT_FAILED"] = "PAYMENT_FAILED";
    MerchantsBillingStatus["TRIAL"] = "TRIAL";
})(MerchantsBillingStatus || (MerchantsBillingStatus = {}));
export var MerchantsJoinChatEventType;
(function (MerchantsJoinChatEventType) {
    MerchantsJoinChatEventType["MESSAGE_DELETED"] = "MESSAGE_DELETED";
    MerchantsJoinChatEventType["MESSAGE_SENT"] = "MESSAGE_SENT";
})(MerchantsJoinChatEventType || (MerchantsJoinChatEventType = {}));
export var NotificationType;
(function (NotificationType) {
    NotificationType["ENQUIRY"] = "ENQUIRY";
    NotificationType["NEW_MESSAGE"] = "NEW_MESSAGE";
    NotificationType["SOMEONE_SPEAKS"] = "SOMEONE_SPEAKS";
    NotificationType["SYSTEM_UPDATE"] = "SYSTEM_UPDATE";
})(NotificationType || (NotificationType = {}));
export var OrderPaymentType;
(function (OrderPaymentType) {
    OrderPaymentType["STRIPE"] = "STRIPE";
})(OrderPaymentType || (OrderPaymentType = {}));
export var OrderStatus;
(function (OrderStatus) {
    OrderStatus["CANCELED"] = "CANCELED";
    OrderStatus["DISPUTE"] = "DISPUTE";
    OrderStatus["DRAFT"] = "DRAFT";
    OrderStatus["PAID"] = "PAID";
    OrderStatus["SUBMITTED"] = "SUBMITTED";
    OrderStatus["UNPAID"] = "UNPAID";
})(OrderStatus || (OrderStatus = {}));
export var PaymentMethod;
(function (PaymentMethod) {
    PaymentMethod["card"] = "card";
    PaymentMethod["eps"] = "eps";
    PaymentMethod["klarna"] = "klarna";
    PaymentMethod["paypal"] = "paypal";
    PaymentMethod["sepa_debit"] = "sepa_debit";
})(PaymentMethod || (PaymentMethod = {}));
export var ProductInventoryStatus;
(function (ProductInventoryStatus) {
    ProductInventoryStatus["AUTOMATIC_STRICT"] = "AUTOMATIC_STRICT";
    ProductInventoryStatus["DISABLED"] = "DISABLED";
    ProductInventoryStatus["OVERSELLING"] = "OVERSELLING";
})(ProductInventoryStatus || (ProductInventoryStatus = {}));
export var ProductStatus;
(function (ProductStatus) {
    ProductStatus["ACTIVE"] = "ACTIVE";
    ProductStatus["COMING_SOON"] = "COMING_SOON";
    ProductStatus["IN_PREPARATION"] = "IN_PREPARATION";
})(ProductStatus || (ProductStatus = {}));
export var ProductType;
(function (ProductType) {
    ProductType["COUPON"] = "COUPON";
    ProductType["PHYSICAL"] = "PHYSICAL";
})(ProductType || (ProductType = {}));
export var ProjectPermission;
(function (ProjectPermission) {
    ProjectPermission["ADMINISTRATOR"] = "ADMINISTRATOR";
    ProjectPermission["OWNER"] = "OWNER";
    ProjectPermission["VIEWER"] = "VIEWER";
})(ProjectPermission || (ProjectPermission = {}));
export var ProjectState;
(function (ProjectState) {
    ProjectState["PUBLISHED"] = "PUBLISHED";
    ProjectState["UNPUBLISHED"] = "UNPUBLISHED";
})(ProjectState || (ProjectState = {}));
export var ProjectType;
(function (ProjectType) {
    ProjectType["EXHIBITION"] = "EXHIBITION";
    ProjectType["GALLERY"] = "GALLERY";
    ProjectType["HALL"] = "HALL";
    ProjectType["SHOP"] = "SHOP";
})(ProjectType || (ProjectType = {}));
export var ShopOrderStatus;
(function (ShopOrderStatus) {
    ShopOrderStatus["PAID"] = "PAID";
    ShopOrderStatus["PENDING"] = "PENDING";
})(ShopOrderStatus || (ShopOrderStatus = {}));
export var SubmitLogInputAction;
(function (SubmitLogInputAction) {
    SubmitLogInputAction["CRITICAL"] = "CRITICAL";
    SubmitLogInputAction["DEBUG"] = "DEBUG";
    SubmitLogInputAction["EXCEPTION"] = "EXCEPTION";
    SubmitLogInputAction["INFO"] = "INFO";
    SubmitLogInputAction["SUCCESS"] = "SUCCESS";
    SubmitLogInputAction["WARNING"] = "WARNING";
})(SubmitLogInputAction || (SubmitLogInputAction = {}));
export var SubmitLogInputMicroservice;
(function (SubmitLogInputMicroservice) {
    SubmitLogInputMicroservice["ADMIN"] = "ADMIN";
    SubmitLogInputMicroservice["API"] = "API";
    SubmitLogInputMicroservice["LANDING"] = "LANDING";
    SubmitLogInputMicroservice["MERCHANTS"] = "MERCHANTS";
    SubmitLogInputMicroservice["VR"] = "VR";
})(SubmitLogInputMicroservice || (SubmitLogInputMicroservice = {}));
export var SubscriptionEventType;
(function (SubscriptionEventType) {
    SubscriptionEventType["added"] = "added";
    SubscriptionEventType["changed"] = "changed";
    SubscriptionEventType["ready"] = "ready";
    SubscriptionEventType["removed"] = "removed";
})(SubscriptionEventType || (SubscriptionEventType = {}));
export var UserActiveNotification;
(function (UserActiveNotification) {
    UserActiveNotification["ENQUIRY"] = "ENQUIRY";
    UserActiveNotification["NEW_MESSAGE"] = "NEW_MESSAGE";
    UserActiveNotification["SOMEONE_SPEAKS"] = "SOMEONE_SPEAKS";
    UserActiveNotification["SYSTEM_UPDATE"] = "SYSTEM_UPDATE";
})(UserActiveNotification || (UserActiveNotification = {}));
export var UserDeletionInfoReason;
(function (UserDeletionInfoReason) {
    UserDeletionInfoReason["FAULTY_SYSTEM"] = "FAULTY_SYSTEM";
    UserDeletionInfoReason["NO_INTEREST"] = "NO_INTEREST";
    UserDeletionInfoReason["OTHER"] = "OTHER";
    UserDeletionInfoReason["SPEED"] = "SPEED";
    UserDeletionInfoReason["UGLY_WORLDS"] = "UGLY_WORLDS";
})(UserDeletionInfoReason || (UserDeletionInfoReason = {}));
export var UserRole;
(function (UserRole) {
    UserRole["ADMIN"] = "ADMIN";
    UserRole["ENDUSER"] = "ENDUSER";
    UserRole["MERCHANT"] = "MERCHANT";
    UserRole["WORLD_ADMIN"] = "WORLD_ADMIN";
})(UserRole || (UserRole = {}));
