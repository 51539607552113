export const form = {
  schema: {},

  init: function () {
    this.fields = [];
    this.state = {
      isValid: false,
    };
  },

  addListenerOnField: function (field) {
    const el = field.el;

    const state = {
      previousValue: el.getAttribute("form-value"),
    };

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "form-value"
        ) {
          const el = mutation.target;

          const newValue = el.getAttribute("form-value");

          if (newValue !== "") {
            el.setAttribute("value", newValue);
          } else {
            el.setAttribute("value", field.placeholder);
          }

          state.previousValue = newValue;

          this.checkValidity();

          this.el.emit("formchange");
        }
      });
    });

    observer.observe(field.el, {
      attributes: true,
    });
  },

  checkValidity: function () {
    for (const field of this.fields) {
      const value = field.el.getAttribute("form-value");

      if (field.required && !value) {
        this.state.isValid = false;
        return;
      }
    }

    this.state.isValid = true;
  },

  addField: function (field) {
    this.addListenerOnField(field);
    this.fields.push(field);
  },

  getState: function () {
    return this.state;
  },

  getFields: function () {
    return this.fields.map((field) => {
      const el = field.el;
      const fieldValue = el.getAttribute("value");
      const placeholder = field.placeholder;

      const value = placeholder && fieldValue === placeholder ? "" : fieldValue;

      return {
        id: el.getAttribute("id"),
        value,
        placeholder,
      };
    });
  },

  updateField: function (fieldId, value) {
    const field = this.getField(fieldId);

    field.setAttribute("value", value);
  },

  getField: function (fieldId) {
    return this.fields.find((field) => field.getAttribute("id") === fieldId);
  },
};

export const formField = {
  schema: {
    placeholder: {
      type: "string",
    },
    maxLength: {
      type: "number",
    },
    required: {
      type: "boolean",
      default: false,
    },
    value: {
      type: "string",
      default: "",
    },
  },

  init: function () {
    const formEl = this.el.closest("[form]");
    const el = this.el;

    this.placeholder = this.data.placeholder;
    this.required = this.data.required;
    this.maxLength = this.data.maxLength;

    if (!formEl) {
      console.error("form-field is not a child of a form element");
      return;
    }

    const id = el.getAttribute("id");
    const value = this.data.value || "";

    if (!id) {
      console.error("form-field must have an id attribute");
      return;
    }

    if (value) {
      this.setValue(value);
      el.setAttribute("value", value);
    } else {
      if (this.placeholder) {
        el.setAttribute("value", this.placeholder);
      }
    }

    const form = formEl.components["form"];

    formEl.addEventListener("loaded", () => {
      form.addField(this);
    });
  },

  setValue: function (value) {
    if (this.maxLength && value.length > this.maxLength) {
      return;
    }

    this.el.setAttribute("form-value", value);
  },

  getValue: function () {
    return this.el.getAttribute("form-value");
  },
};
