import { gql } from "@apollo/client";
export const SHOP_GET_PRODUCTS = (lang) => gql `
  query ($input: EndUsersGetShopProductsInput!) {
    EndUsersGetShopProducts(input: $input) {
      id
      slug

      name {
        text(lang: ${lang})
      }

      description {
        text(lang: ${lang})
      }

      pricesEur

      imageUrl

      isComingSoon
    }
  }
`;
