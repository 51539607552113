export const customLayout = {
    schema: {
        type: { type: "string", default: "space-between" },
        coordinate: { type: "string", default: "x" },
    },
    init: function () {
        this.updateLayout = this.updateLayout.bind(this);
        this.el.addEventListener("child-attached", this.updateLayout);
        this.el.addEventListener("child-detached", this.updateLayout);
        this.el.addEventListener("change-visible", this.updateLayout);
        if (this.data.type === "line" ||
            this.data.type === "space-between" ||
            this.data.type === "space-around") {
            this.updateLayout();
        }
    },
    updateLayout: function () {
        const children = this.el.children;
        const type = this.data.type;
        const coordinate = this.data.coordinate;
        let totalWidth;
        let totalWidthElement = this.el;
        while (!totalWidth) {
            totalWidth = totalWidthElement.getAttribute("width");
            totalWidthElement = totalWidthElement.parentElement;
        }
        let totalHeight;
        let totalHeightElement = this.el;
        while (!totalHeight) {
            totalHeight = totalHeightElement.getAttribute("height");
            totalHeightElement = totalHeightElement.parentElement;
        }
        let totalChildren = 0;
        const visibleChildren = [];
        for (let i = 0; i < children.length; i++) {
            const isVisible = children[i].getAttribute("visible");
            if (isVisible) {
                totalChildren++;
                visibleChildren.push(children[i]);
            }
        }
        if (type === "space-between") {
            for (let i = 0; i < totalChildren; i++) {
                const child = visibleChildren[i];
                const position = child.getAttribute("position");
                let xCoordinate = position.x;
                let yCoordinate = position.y;
                const zCoordinate = position.z;
                if (coordinate === "x") {
                    xCoordinate =
                        (i - (totalChildren - 1) / 2) * (totalWidth / totalChildren);
                }
                else if (coordinate === "y") {
                    yCoordinate =
                        -(i - (totalChildren - 1) / 2) * (totalHeight / totalChildren);
                }
                child.setAttribute("position", `${xCoordinate} ${yCoordinate} ${zCoordinate}`);
            }
        }
        else if (type === "space-around") {
            const spacing = totalWidth / (totalChildren + 1);
            for (let i = 0; i < totalChildren; i++) {
                const child = visibleChildren[i];
                const position = child.getAttribute("position");
                let xCoordinate = position.x;
                let yCoordinate = position.y;
                const zCoordinate = position.z;
                if (coordinate === "x") {
                    xCoordinate = (i + 1) * spacing - totalWidth / 2;
                }
                else if (coordinate === "y") {
                    yCoordinate = -(i + 1) * spacing + totalHeight / 2;
                }
                child.setAttribute("position", `${xCoordinate} ${yCoordinate} ${zCoordinate}`);
            }
        }
    },
    remove: function () {
        this.el.removeEventListener("child-attached", this.updateLayout);
        this.el.removeEventListener("child-detached", this.updateLayout);
    },
};
