import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useRouter, useTranslate } from "@bluelibs/x-ui";
import { Button } from "@bundles/UIAppBundle/components/button";
import Dropdown from "@bundles/UIAppBundle/components/dropdown/Dropdown";
import { IconArrowLeft } from "@bundles/UIAppBundle/components/svg/icons";
import { AcceptedLanguage } from "@root/api.types";
import { useLanguageSwitcher } from "@bundles/UIAppBundle/hooks";
const CheckoutHeader = ({ logo, onBack }) => {
    const t = useTranslate("components.checkout.header");
    const { onLanguageChange, activeLanguage } = useLanguageSwitcher();
    const router = useRouter();
    const dropdownItems = [
        {
            label: "English",
            url: "#",
            onClick: () => {
                onLanguageChange(AcceptedLanguage.en, true);
            },
        },
        {
            label: "Deutsch",
            url: "#",
            onClick: () => {
                onLanguageChange(AcceptedLanguage.de), true;
            },
        },
    ];
    return (_jsxs("div", Object.assign({ className: "cc-checkout-header" }, { children: [_jsxs("div", Object.assign({ className: "cc-checkout-header__back", onClick: onBack }, { children: [_jsx(Button, Object.assign({ bgColor: "dark-gray", color: "white", size: "custom", className: "cc-checkout-header__back-btn" }, { children: _jsx(IconArrowLeft, {}, void 0) }), void 0), _jsx("span", { children: t("back") }, void 0)] }), void 0), _jsx("div", Object.assign({ className: "cc-checkout-header__logo" }, { children: _jsx("img", { src: logo, alt: "" }, void 0) }), void 0), _jsx(Dropdown, { headerButton: _jsxs(Button, Object.assign({ size: "custom", bgColor: "white", color: "gray", className: "cc-lang-switcher__btn" }, { children: [activeLanguage, " ", _jsx(IconArrowLeft, {}, void 0)] }), void 0), items: dropdownItems, position: "bottom" }, void 0)] }), void 0));
};
export default CheckoutHeader;
