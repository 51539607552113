import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import Sidebar from "../sidebar/Sidebar";
import { Button } from "@bundles/UIAppBundle/components/button";
import { useTranslate } from "@bluelibs/x-ui-i18n-bundle";
import { useUISession } from "@bluelibs/x-ui-session-bundle";
const ObjectDetailsSidebar = ({ isOpen, onClose, product, toggleChat, }) => {
    var _a, _b, _c;
    const t = useTranslate("components.objectDetails");
    const session = useUISession();
    const language = session.get("currentLocale");
    const getTextBasedOnLanguage = (input) => {
        var _a, _b, _c;
        return (_c = (_b = (_a = input === null || input === void 0 ? void 0 : input.values) === null || _a === void 0 ? void 0 : _a.find((value) => (value === null || value === void 0 ? void 0 : value.lang) === language)) === null || _b === void 0 ? void 0 : _b.text) !== null && _c !== void 0 ? _c : "";
    };
    if (!isOpen)
        return null;
    const image = ((_c = (_b = (_a = product === null || product === void 0 ? void 0 : product.media) === null || _a === void 0 ? void 0 : _a.image2dFiles) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.downloadUrl) || "";
    return (_jsxs(Sidebar, Object.assign({ isMobile: AFRAME.utils.device.isMobile(), title: t("details"), onClose: onClose }, { children: [_jsxs("div", Object.assign({ className: "cc-details-wrapper" }, { children: [image && (_jsx("div", Object.assign({ className: "cc-details-image" }, { children: _jsx("div", Object.assign({ className: "cc--of-bg" }, { children: _jsx("img", { className: "cc--of", src: image }, void 0) }), void 0) }), void 0)), _jsx("h6", Object.assign({ className: "cc--text-color-primary" }, { children: getTextBasedOnLanguage(product === null || product === void 0 ? void 0 : product.name) }), void 0), _jsx("p", { children: getTextBasedOnLanguage(product === null || product === void 0 ? void 0 : product.description) }, void 0)] }), void 0), _jsx(Button, Object.assign({ className: "cc--full-width", onClick: toggleChat }, { children: t("chatUs") }), void 0)] }), void 0));
};
export default ObjectDetailsSidebar;
