import { gql } from "@apollo/client";
export const SHOP_GET_ALL_PRODUCTS = (lang) => gql `
    query EndUsersShopsGetAllProducts($input: EndUsersShopsGetAllProductsInput!) {
        EndUsersShopsGetAllProducts(input: $input) {
            id
            slug
            name {
                text(lang: ${lang})
            }
            description {
                text(lang: ${lang})
            }
            imageUrl
            pricesEur
            isComingSoon
            shopLinkName
        }
    }
`;
