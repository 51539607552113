import { gql } from "@apollo/client";
export const PROJECTS_FIND_ONE = (lang) => gql `
  fragment FileData on AppFile {
    _id

    name
    path
    downloadUrl
  }

  fragment MediaData on Media {
    _id
    name
    videoUrl

    image360Files {
      ...FileData
    }

    image2dFiles {
      ...FileData
    }

    image3dOBJ {
      ...FileData
    }

    image3dMTL {
      ...FileData
    }

    image3DGLB {
      ...FileData
    }

    videoUpload {
      ...FileData
    }
  }

  query ($input: EndUsersGetProjectInput!) {
    EndUsersGetProject(input: $input) {
      _id

      name

      state
      type

      merchantId
    
      world {
        _id

        name {
          text(lang: ${lang})
        }

        description {
          text(lang: ${lang})
        }

        startCoordinates {
          position
          rotation
          scale
        }

        merchantDefaultVideoCoordinates {
          position
          rotation
          scale
        }

        capacity
        assetsCount

        pictureGallery {
          _id
          name

          files {
            ...FileData
          }
        }

        worldFile {
          ...FileData
        }

        meshFile {
          ...FileData
        }
      }

      assetInfos {
        _id
        
        projectId

        isEnabled

        mediaType
        typeFor3D

        deltaCoordinates {
          position
          rotation
          scale
        }

        assetPosition {
          coordinates {
            position
            rotation
            scale
          }

          mediaType
          typeFor3D

          media {
            ...MediaData
          }
        }

        action {
          type
          projectId
        }

        product {
          _id

          name {
            text(lang: ${lang})
            values {
              lang
              text
            }
          }

          description {
            text(lang: ${lang})
            values {
              lang
              text
            }
          }

          vat

          manufacturer
          href
          tags

          inventory {
            status
          }

          options {
            sizes
            colours
          }

          shipping {
            weight
            originCountryOrRegion
            harmonisedSystemCode
          }

          seo {
            title
            description
          }

          category {
            _id
            name
          }

          media {
            ...MediaData
          }
        }
      }

      createdAt
      createdBy {
        _id
        fullName
      }

      updatedAt
      updatedBy {
        _id
        fullName
      }

      worldId

      billing {
        packageType
        expiryDate
        status

        features
      }

      merchant {
        isAvailableToChat
        owner {
          fullName
          profile {
            firstName
            lastName
          }
          isEnabled
        }
      }
    }
  }
`;
