import { Kernel } from "@bluelibs/core";
import { XUIBundle } from "@bluelibs/x-ui";
import { AppGuardian } from "@bundles/UIAppBundle/services/AppGuardian";
import { UIAppBundle } from "../bundles/UIAppBundle/UIAppBundle";
import env from "./env";
import { QueryParamProvider } from "use-query-params";
import Loader from "@bundles/UIAppBundle/components/loader/Loader";
// All UI bundles need to be prefixed with UI
// All X-Framework bundles have the first prefix X
export const kernel = new Kernel({
    bundles: [
        new XUIBundle({
            graphql: {
                // ApolloClient Options
                // https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClientOptions
                uri: env.API_URL,
            },
            enableSubscriptions: true,
            apollo: {
                enableSubscriptions: true,
            },
            react: {
                initialisingComponent: Loader,
                wrappers: [
                    {
                        component: QueryParamProvider,
                    },
                ],
            },
            sessions: {
                defaults: {
                    wishlist: {},
                    chats: {},
                },
            },
            guardian: {
                loadingComponent: Loader.bind(null, { centerInScreen: true }),
                guardianClass: AppGuardian,
            },
        }),
        new UIAppBundle(),
    ],
});
