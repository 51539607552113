export const keyboard = {
  schema: {
    keyboardColor: { type: "string", default: "#333" },
    keysColor: { type: "string", default: "#2B2B2B" },
    keysIntersectedColor: { type: "string", default: "#555" },
    keysClickedColor: { type: "string", default: "#777" },
    controllerId: { type: "string", default: "#right-hand" },
  },
  init: function () {
    this.input = "";

    this.isUppercase = false;
    this.el.setAttribute("material", { color: this.data.keyboardColor });

    // Define key constants
    this.KEY_DELETE = "delete";
    this.KEY_ENTER = "return";
    this.KEY_SHIFT_LEFT = "shift";
    this.KEY_SHIFT_RIGHT = "shift";
    this.KEY_SPACE = " ";
    this.KEY_COMMA = ",";
    this.KEY_PERIOD = ".";
    this.KEY_MICROPHONE = "mic";
    this.KEY_EMPTY = "";

    this.SPECIAL_KEYS = [
      this.KEY_DELETE,
      this.KEY_ENTER,
      this.KEY_SHIFT_LEFT,
      this.KEY_SHIFT_RIGHT,
      this.KEY_SPACE,
      this.KEY_COMMA,
      this.KEY_PERIOD,
      this.KEY_MICROPHONE,
    ];

    const spawnSizes = {
      [this.KEY_EMPTY]: [1, 1],
      [this.KEY_DELETE]: [1, 1],
      [this.KEY_ENTER]: [2, 1],
      [this.KEY_SHIFT_LEFT]: [1, 2],
      [this.KEY_SPACE]: [1, 5],
      [this.KEY_COMMA]: [1, 1],
      [this.KEY_PERIOD]: [1, 1],
      [this.KEY_MICROPHONE]: [1, 2],
      1: [1, 1],
      2: [1, 1],
      3: [1, 1],
      4: [1, 1],
      5: [1, 1],
      6: [1, 1],
      7: [1, 1],
      8: [1, 1],
      9: [1, 1],
      0: [1, 1],
      Q: [1, 1],
      W: [1, 1],
      E: [1, 1],
      R: [1, 1],
      T: [1, 1],
      Y: [1, 1],
      U: [1, 1],
      I: [1, 1],
      O: [1, 1],
      P: [1, 1],
      A: [1, 1],
      S: [1, 1],
      D: [1, 1],
      F: [1, 1],
      G: [1, 1],
      H: [1, 1],
      J: [1, 1],
      K: [1, 1],
      L: [1, 1],
      '"': [1, 1],
      Z: [1, 1],
      X: [1, 1],
      C: [1, 1],
      V: [1, 1],
      B: [1, 1],
      N: [1, 1],
      M: [1, 1],
    };

    // Define the keyboard layout
    const keyboard = [
      ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", this.KEY_DELETE],
      [
        "Q",
        "W",
        "E",
        "R",
        "T",
        "Y",
        "U",
        "I",
        "O",
        "P",
        this.KEY_EMPTY,
        this.KEY_ENTER,
      ],
      [this.KEY_EMPTY, "A", "S", "D", "F", "G", "H", "J", "K", "L", '"'],
      [
        this.KEY_SHIFT_LEFT,
        "Z",
        "X",
        "C",
        "V",
        "B",
        "N",
        "M",
        this.KEY_SHIFT_RIGHT,
      ],
      [this.KEY_MICROPHONE, this.KEY_SPACE, this.KEY_COMMA, this.KEY_PERIOD],
    ];

    const keyboardWidth = this.el.getAttribute("width");
    const keyboardHeight = this.el.getAttribute("height");

    const numberOfRows = keyboard.length;
    const numberOfCols = Math.max(
      ...keyboard.map((row) =>
        row.reduce((acc, key) => acc + spawnSizes[key][1], 0)
      )
    );

    const spacing = 0.01; // Space between keys
    const padding = 0.01;

    const keyWidthFactor =
      (keyboardWidth - 2 * padding - (numberOfCols - 1) * spacing) /
      numberOfCols;
    const keyHeightFactor =
      (keyboardHeight - 2 * padding - (numberOfRows - 1) * spacing) /
      numberOfRows;

    const startX = padding - keyboardWidth / 2;
    const startY = keyboardHeight / 2 - padding;

    // Create each key based on its layout
    keyboard.forEach((row, rowIndex) => {
      let totalWidth = 0; // Track the total width for positioning

      row.forEach((key, colIndex) => {
        const [numberOfSpawnedRows, numberOfSpawnedColumns] = spawnSizes[key];

        const keyHeight =
          numberOfSpawnedRows * keyHeightFactor +
          spacing * (numberOfSpawnedRows - 1);
        const keyWidth =
          numberOfSpawnedColumns * keyWidthFactor +
          spacing * (numberOfSpawnedColumns - 1);

        if (key === this.KEY_EMPTY) {
          totalWidth += keyWidth + spacing;

          return;
        }

        const x = startX + totalWidth + keyWidth / 2;
        const y =
          startY -
          keyHeight / 2 -
          rowIndex * (keyHeight + spacing) +
          (numberOfSpawnedRows - 1) * keyHeightFactor +
          spacing * (numberOfSpawnedRows - 1);

        this.createKey(key, x, y, keyWidth, keyHeight);

        totalWidth += keyWidth + spacing;
      });
    });
  },

  createKey: function (label, x, y, width, height) {
    // TODO: should work with more controllers?
    const controller = document.querySelector(this.data.controllerId);

    const key = document.createElement("a-entity");
    key.classList.add("keyboard-key");
    key.setAttribute("geometry", {
      primitive: "plane",
      width: width,
      height: height,
    });
    key.classList.add("clickable");
    key.setAttribute("position", `${x} ${y} 0.001`);
    key.setAttribute("material", {
      color: this.data.keysColor,
      side: "double",
    });

    key.addEventListener("raycaster-intersected", () => {
      key.setAttribute("material", {
        color: this.data.keysIntersectedColor,
      });
    });

    key.addEventListener("raycaster-intersected-cleared", () => {
      key.setAttribute("material", { color: this.data.keysColor });
    });

    controller.addEventListener("triggerup", () => {
      const raycaster = controller.components.raycaster;

      const intersection = raycaster.intersections[0];

      if (intersection.object.el !== key) {
        return;
      }

      key.setAttribute("material", {
        color: this.data.keysIntersectedColor,
      });
    });
    controller.addEventListener("triggerdown", () => {
      const raycaster = controller.components.raycaster;

      const intersection = raycaster.intersections[0];

      if (intersection.object.el !== key) {
        return;
      }

      this.onKeyPressed(label);
      key.setAttribute("material", {
        color: this.data.keysClickedColor,
      });
    });

    const text = document.createElement("a-text");
    text.setAttribute("value", label.toLowerCase());
    text.setAttribute("align", "center");
    text.setAttribute("color", "#fff");
    text.setAttribute("width", width);
    text.setAttribute("scale", "4 4 4");

    key.appendChild(text);

    this.el.appendChild(key);
  },

  onKeyPressed: function (key) {
    if (key === this.KEY_DELETE) {
      this.input = this.input.slice(0, -1);
    } else if (key === this.KEY_MICROPHONE) {
      console.info("Microphone key pressed");
    } else if (key === this.KEY_ENTER) {
      this.onEnterPressed();
    } else if (key === this.KEY_SHIFT_LEFT) {
      this.onShiftPressed();
    } else {
      key = this.isUppercase ? key : key.toLowerCase();
      this.input += key;
    }

    this.el.emit("key-pressed", { key: key, input: this.input });
  },

  onEnterPressed: function () {
    this.el.emit("enter-pressed", { input: this.input });
    this.input = "";
  },

  setInput: function (input) {
    this.input = input;
  },

  // Update the layout of the keyboard
  onShiftPressed: function () {
    const keys = document.querySelectorAll(".keyboard-key");

    for (const key of keys) {
      const text = key.querySelector("a-text");

      const value = text.getAttribute("value");

      if (this.SPECIAL_KEYS.includes(value)) {
        continue;
      }

      text.setAttribute(
        "value",
        this.isUppercase ? value.toLowerCase() : value.toUpperCase()
      );
    }

    this.isUppercase = !this.isUppercase;
  },
};
