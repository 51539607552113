import { jsx as _jsx } from "react/jsx-runtime";
import { useQuery } from "@apollo/client";
import { EJSON } from "@bluelibs/ejson";
import { newSmart, useRouter, useSmart, useTranslate, useUISession, } from "@bluelibs/x-ui";
import { Error } from "@bluelibs/x-ui-react-bundle/dist/react/components";
import { CheckoutMainLayout } from "@bundles/UIAppBundle/components/checkout";
import Loader from "@bundles/UIAppBundle/components/loader/Loader";
import { GET_MERCHANT_LOGO } from "@bundles/UIAppBundle/queries/getMerchantLogo.query";
import { useAppGuardian } from "@bundles/UIAppBundle/services";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { WishlistSmart } from "@bundles/UIAppBundle/smarts/Wishlist.smart";
import env from "@root/startup/env";
import { GET_PROJECT_REDIRECT_INQUIRY_URL } from "@bundles/UIAppBundle/queries/projectFindInquiryRedirectURL.query";
import { useLanguageSwitcher } from "@bundles/UIAppBundle/hooks";
export const WishlistPageWrapper = () => {
    const [_, WishlistSmartProvider] = newSmart(WishlistSmart);
    return (_jsx(WishlistSmartProvider, { children: _jsx(WishlistPage, {}, void 0) }, void 0));
};
export const WishlistPage = () => {
    const t = useTranslate("pages.wishlist");
    const uiSession = useUISession();
    const wishlistSmart = useSmart(WishlistSmart);
    const [shouldClosePageOnBack, setShouldClosePageOnBack] = useState(null);
    const guardian = useAppGuardian();
    const localStorageProjectId = localStorage.getItem("projectId");
    const router = useRouter();
    const projectId = uiSession.get("projectId", localStorageProjectId ? EJSON.parse(localStorageProjectId) : null);
    const [form] = useForm();
    const [logo, setLogo] = useState();
    const { loading: loadingLogo, error: errorLogo } = useQuery(GET_MERCHANT_LOGO, {
        variables: {
            input: {
                projectId,
            },
        },
        onCompleted: (data) => setLogo(data.EndUsersGetMerchantLogo),
        skip: !projectId,
    });
    const { data: getProjectRedirectInquiryUrlData } = useQuery(GET_PROJECT_REDIRECT_INQUIRY_URL, {
        variables: {
            input: {
                projectId,
            },
        },
        skip: !projectId,
    });
    const { activeLanguage, onLanguageChange } = useLanguageSwitcher();
    useEffect(() => {
        if (!wishlistSmart.state.initialised)
            return;
        wishlistSmart.updateWishlistItems();
    }, [activeLanguage, wishlistSmart.state.initialised]);
    useEffect(() => {
        const current = Boolean(uiSession.state.wentToCheckoutFromTheApp);
        setShouldClosePageOnBack(current);
        uiSession.set("wentToCheckoutFromTheApp", false, { persist: true });
    }, []);
    const redirectUrl = (getProjectRedirectInquiryUrlData === null || getProjectRedirectInquiryUrlData === void 0 ? void 0 : getProjectRedirectInquiryUrlData.MerchantProjectsGetInquiryRedirectURL) ||
        env.LANDING_APP_URL;
    const validateInput = (input) => {
        if (typeof input.email === "undefined" || input.email.length === 0) {
            return { isValid: false, error: "Email is required" };
        }
        const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!input.email.match(emailRegex)) {
            return { isValid: false, error: "Invalid email address" };
        }
        if (typeof input.firstName === "undefined" ||
            input.firstName.length === 0) {
            return { isValid: false, error: "Last name is required" };
        }
        if (typeof input.lastName === "undefined" || input.lastName.length === 0) {
            return { isValid: false, error: "First name is required" };
        }
        if (typeof input.phoneNumber === "undefined" ||
            input.phoneNumber.length === 0) {
            return { isValid: false, error: "Phone number is required" };
        }
        return { isValid: true };
    };
    const onSubmit = async (input) => {
        const { isValid, error: errorStr } = validateInput(input);
        if (!isValid) {
            toast.error(errorStr);
            return;
        }
        try {
            await wishlistSmart.sendInquiry(input);
            form.resetFields();
            uiSession.set("merchantId", null, { persist: true });
            uiSession.set("projectId", null, { persist: true });
            uiSession.set("projectLinkName", null, { persist: true });
            toast.info(t("inquirySent"));
            setTimeout(() => {
                window.location.replace(redirectUrl);
            }, 3000);
        }
        catch (err) {
            toast.error(err.toString());
        }
    };
    const onLogin = async (data) => {
        const { username, password } = data;
        try {
            await guardian.login(username, password);
            await guardian.load();
            toast.info(t("loginSuccessful"));
        }
        catch (err) {
            toast.error(err.toString());
        }
    };
    const onRegister = async (input) => {
        const { isValid, error: errorStr } = validateInput(input);
        if (!isValid) {
            toast.error(errorStr);
            return;
        }
        try {
            await wishlistSmart.sendInquiryAndCreateAccount(input);
            form.resetFields();
            uiSession.set("merchantId", null, { persist: true });
            uiSession.set("projectId", null, { persist: true });
            uiSession.set("projectLinkName", null, { persist: true });
            toast.info(t("inquirySent"));
            setTimeout(() => {
                window.location.replace(redirectUrl);
            }, 3000);
        }
        catch (err) {
            toast.error(err.toString());
        }
    };
    // if (error) {
    //   return <Error error={error} />;
    // }
    // if (!projectId) {
    //   router.go(Routes.HOME);
    //   return;
    // }
    if (errorLogo) {
        return _jsx(Error, { error: errorLogo }, void 0);
    }
    if (wishlistSmart.state.isLoading || loadingLogo) {
        return _jsx(Loader, {}, void 0);
    }
    return (_jsx(CheckoutMainLayout, Object.assign({ shouldClosePageOnBack: shouldClosePageOnBack, items: wishlistSmart.state.wishlist.map(({ quantity, product: item }) => {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            return ({
                id: item._id,
                image: ((_c = (_b = (_a = item.media) === null || _a === void 0 ? void 0 : _a.image2dFiles) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.downloadUrl) ||
                    ((_f = (_e = (_d = item.media) === null || _d === void 0 ? void 0 : _d.image360Files) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.downloadUrl),
                text: (_g = item.description) === null || _g === void 0 ? void 0 : _g.text,
                title: (_h = item.name) === null || _h === void 0 ? void 0 : _h.text,
                units: quantity,
            });
        }), logo: logo === null || logo === void 0 ? void 0 : logo.downloadUrl, onSendInquiry: onSubmit, changeQuantity: wishlistSmart.changeQuantity }, {
        onLogin,
        onRegister,
    }), void 0));
};
