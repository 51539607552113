import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from "react";
import styles from "./ShopProductDetail.module.scss";
import Loader from "@bundles/UIAppBundle/components/loader/Loader";
import { Input } from "@bundles/UIAppBundle/components/form";
import { useSmart } from "@bluelibs/smart";
import { BasketSmart } from "@bundles/UIAppBundle/smarts/Basket.smart";
import { toast } from "react-toastify";
import { useTranslate } from "@bluelibs/x-ui-i18n-bundle";
import { ShopButton } from "../button";
import { ShopInfoComponent } from "../shop-info";
import { StringParam, useQueryParams } from "use-query-params";
import classNames from "classnames";
export const ShopProductDetail = ({ product, shopInfo }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const basketSmart = useSmart(BasketSmart);
    const [queryParams, setQueryParams] = useQueryParams({
        variant: StringParam,
    });
    const [quantity, setQuantity] = useState(1);
    const [selectedVariant, setSelectedVariant] = useState(() => {
        if (queryParams.variant) {
            const variant = product.variants.find((variant) => variant.slug === queryParams.variant);
            if (variant) {
                return variant;
            }
            setQueryParams({
                variant: undefined,
            });
        }
        return product.variants[0];
    });
    const t = useTranslate("components.shopProductDetail");
    useEffect(() => {
        const basketProduct = basketSmart.basket.find((item) => item.productId === product.id && item.variantId === selectedVariant.id);
        if (basketProduct) {
            setQuantity(basketProduct.quantity);
        }
    }, [basketSmart.basket, selectedVariant]);
    const setVariant = useCallback((variant) => {
        setSelectedVariant(variant);
        setQueryParams({ variant: variant.slug });
    }, []);
    if (!product) {
        return _jsx(Loader, { centerInScreen: true }, void 0);
    }
    const handleThumbnailClick = (index) => {
        setCurrentImageIndex(index);
    };
    const onAddToCart = async () => {
        if (selectedVariant.stock != null && quantity > selectedVariant.stock) {
            toast.error(t("quantityExceedsStock", { stock: selectedVariant.stock }));
            return;
        }
        await basketSmart.addToBasket({
            productId: product.id,
            variantId: selectedVariant.id,
            quantity,
            linkName: shopInfo.linkName,
        });
        toast.success(t("addedToCart"));
    };
    const onUpdateQuantity = async () => {
        if (selectedVariant.stock != null && quantity > selectedVariant.stock) {
            toast.error(t("quantityExceedsStock", { stock: selectedVariant.stock }));
            return;
        }
        await basketSmart.changeQuantity({
            productId: product.id,
            variantId: selectedVariant.id,
            quantity,
        });
        toast.success(t("quantityUpdated"));
    };
    const onDelete = async () => {
        await basketSmart.removeFromBasket({
            productId: product.id,
            variantId: selectedVariant.id,
        });
        toast.success(t("removedFromCart"));
    };
    const cartProduct = basketSmart.basket.find((item) => item.productId === product.id && item.variantId === selectedVariant.id);
    return (_jsxs("div", Object.assign({ className: styles.wrapper }, { children: [_jsxs("div", Object.assign({ className: styles.productDetail }, { children: [product.isComingSoon && (_jsx("div", Object.assign({ className: styles.comingSoonBanner }, { children: t("isComingSoon") }), void 0)), _jsxs("div", Object.assign({ className: styles.productImage }, { children: [_jsx("img", { src: product.imageUrls[currentImageIndex], alt: product.name.text, className: styles.mainProductImg }, void 0), _jsx("div", Object.assign({ className: styles.thumbnailContainer }, { children: product.imageUrls.map((url, index) => (_jsx("img", { src: url, alt: `${product.name.text} thumbnail #${index}`, onClick: () => handleThumbnailClick(index), className: styles.thumbnailImg }, index))) }), void 0)] }), void 0), _jsxs("div", Object.assign({ className: styles.productContent }, { children: [_jsx("span", Object.assign({ className: styles.title }, { children: product.name.text }), void 0), _jsx("div", { className: styles.spacer }, void 0), _jsx("span", Object.assign({ className: styles.description }, { children: product.description.text.split("\n").map((text, index) => (_jsxs("span", { children: [text, _jsx("br", {}, void 0)] }, index))) }), void 0), _jsx("hr", { className: styles.hr }, void 0), _jsxs("span", Object.assign({ className: styles.price }, { children: ["\u20AC", selectedVariant.priceEur] }), void 0), _jsxs("div", Object.assign({ className: styles.quantity }, { children: [_jsxs("span", { children: [t("quantity"), ":"] }, void 0), _jsx(Input, { placeholder: "Quantity", label: "Quantity", type: "number", max: selectedVariant.stock != null
                                            ? selectedVariant.stock
                                            : undefined, value: quantity, onChange: (e) => setQuantity(parseInt(e.target.value, 10)), min: "1" }, void 0)] }), void 0), product.variants.length > 1 && (_jsx("div", Object.assign({ className: styles.variants }, { children: product.variants.map((variant, index) => (_jsxs("div", Object.assign({ className: classNames(styles.variantOption, {
                                        [styles.selected]: selectedVariant.id === variant.id,
                                        [styles.disabled]: variant.isOutOfStock,
                                    }), onClick: variant.isOutOfStock ? null : () => setVariant(variant) }, { children: [_jsx("span", Object.assign({ className: styles.variantTitle }, { children: variant.name }), void 0), _jsx("div", Object.assign({ className: styles.stock }, { children: variant.isOutOfStock && (_jsx("span", Object.assign({ className: styles.outOfStock }, { children: t("outOfStock") }), void 0)) }), void 0), _jsxs("span", Object.assign({ className: styles.variantPrice }, { children: ["\u20AC", variant.priceEur] }), void 0)] }), variant.id))) }), void 0)), !product.isComingSoon && (_jsxs("div", Object.assign({ className: styles.buttons }, { children: [!cartProduct && (_jsx(ShopButton, Object.assign({ className: classNames({
                                            [styles.addToCartButton]: true,
                                            [styles.disabled]: selectedVariant.isOutOfStock,
                                        }), disabled: selectedVariant.isOutOfStock, onClick: onAddToCart }, { children: t("addToCart") }), void 0)), cartProduct && (_jsxs("div", Object.assign({ style: { display: "flex", justifyContent: "space-between" } }, { children: [_jsx(ShopButton, Object.assign({ className: styles.updateQuantityButton, onClick: onUpdateQuantity }, { children: t("updateQuantity") }), void 0), _jsx(ShopButton, Object.assign({ className: styles.removeFromCartButton, onClick: onDelete }, { children: t("removeFromCart") }), void 0)] }), void 0))] }), void 0))] }), void 0)] }), void 0), _jsx(ShopInfoComponent, { shopInfo: shopInfo }, void 0)] }), void 0));
};
