export const chat = {
  schema: {
    primaryColor: { type: "string" },
    disabledColor: { type: "string" },
    headerHeight: { type: "number" },

    height: { type: "number" },
    width: { type: "number" },

    messageHeight: { type: "number" },
    messageSpacing: { type: "number" },

    scrollBarWidth: { type: "number" },
    secondaryColor: { type: "string" },
    scrollBarThumbColor: { type: "string" },

    chatHeight: { type: "number" },

    keyboardHeight: { type: "number" },
    keyboardWidth: { type: "number" },

    firstName: { type: "string" },
    lastName: { type: "string" },
  },
  init: function () {
    this.messages = [];
    this.lastFirstMessageIndex = null;

    this.messageHeight = this.data.messageHeight;
    this.primaryColor = this.data.primaryColor;
    this.disabledColor = this.data.disabledColor;
    this.messageSpacing = this.data.messageSpacing;
    this.height = this.data.height;
    this.width = this.data.width;
    this.scrollBarWidth = this.data.scrollBarWidth;
    this.secondaryColor = this.data.secondaryColor;
    this.scrollBarThumbColor = this.data.scrollBarThumbColor;
    this.chatHeight = this.data.chatHeight;
    this.keyboardHeight = this.data.keyboardHeight;
    this.keyboardWidth = this.data.keyboardWidth;
    this.headerHeight = this.data.headerHeight;
    this.thumbProgress = 1;

    this.firstName = this.data.firstName;
    this.lastName = this.data.lastName;

    this.keyboardCurrentlyFor = null;

    this.messageWidth = this.width - this.messageSpacing * 2;
    this.headerWidth = this.width + this.scrollBarWidth;

    this.numberOfDisplayableMessages = Math.floor(
      this.height / (this.messageHeight + this.messageSpacing)
    );
    this.firstMessagePosition =
      (this.height - this.messageHeight) / 2 - this.messageSpacing;
    this.lastMessagePosition = -this.firstMessagePosition;

    this.chatPosition = this.el.getAttribute("position");

    if (this.firstName && this.lastName) {
      this.createChat();
    } else {
      this.createSetupEntity();
    }
  },

  getRelativeY(height) {
    return height / 2 + this.height / 2 + this.chatPosition.y;
  },

  getRelativeZ(z) {
    return z + this.chatPosition.z;
  },

  createSetupEntity() {
    const headerSettingsHeight = (4 / 7) * this.height;
    const formId = "chat-setup-form";

    const optionsEntity = this.createOptionsEntity({
      height: headerSettingsHeight,
      formId,
      title: "Set your chat",
      closeButtonText: "Skip",
      width: this.headerWidth,
      onOk: (entity) => {
        this.killKeyboard();
        this.el.removeChild(entity);
        this.createChat();
        this.el.emit("setup-chat", {
          firstName: this.firstName,
          lastName: this.lastName,
        });
      },
      onCancel: (entity) => {
        // TODO: you could make this nicer, with random names
        this.firstName = "Anonymous";
        this.lastName = "User";
        this.el.removeChild(entity);
        this.createChat();
        this.el.emit("setup-chat", {
          firstName: this.firstName,
          lastName: this.lastName,
        });
      },
      position: `${-this.width / 2 + this.headerWidth / 2} ${this.getRelativeY(
        -this.height / 2 - headerSettingsHeight
      )} ${this.getRelativeZ(0.01)}`,
    });

    this.el.appendChild(optionsEntity);
  },

  createChat() {
    this.chatContent = document.createElement("a-plane");
    this.chatContent.setAttribute("height", this.height);
    this.chatContent.setAttribute("width", this.width);
    this.chatContent.setAttribute("position", this.chatPosition);
    this.chatContent.setAttribute(
      "material",
      "opacity: 0.7; transparent: true;"
    );
    this.chatContent.setAttribute("color", this.primaryColor);
    this.el.appendChild(this.chatContent);

    this.header = document.createElement("a-plane");
    this.header.setAttribute("height", this.headerHeight);
    this.header.setAttribute("width", this.headerWidth);
    this.header.setAttribute(
      "position",
      `${this.scrollBarWidth / 2} ${this.getRelativeY(
        this.headerHeight
      )} ${this.getRelativeZ(0.001)}`
    );
    this.header.setAttribute("color", this.primaryColor);
    this.header.setAttribute(
      "custom-layout",
      "coordinate: x; type: space-between"
    );

    const headerCloseButton = document.createElement("a-image");
    headerCloseButton.setAttribute("src", "#icon-close");
    headerCloseButton.setAttribute("height", this.headerHeight / 2);
    headerCloseButton.setAttribute("width", this.headerHeight / 2);
    headerCloseButton.classList.add("clickable");
    headerCloseButton.setAttribute("position", `0 0 0.001`);
    headerCloseButton.addEventListener("click", () => {
      this.el.parentNode.removeChild(this.el);
    });
    this.header.appendChild(headerCloseButton);

    const headerSettingsButton = document.createElement("a-image");
    headerSettingsButton.setAttribute("src", "#icon-settings");
    headerSettingsButton.classList.add("clickable");
    headerSettingsButton.setAttribute("height", this.headerHeight / 2);
    headerSettingsButton.setAttribute("width", this.headerHeight / 2);
    headerSettingsButton.setAttribute("position", `0 0 0.001`);
    this.header.appendChild(headerSettingsButton);

    headerSettingsButton.addEventListener("click", () => {
      if (this.headerSettings && this.el.contains(this.headerSettings)) {
        this.el.removeChild(this.headerSettings);
        this.killKeyboard();
        return;
      }

      const headerSettingsHeight = (4 / 7) * this.height;
      this.headerSettings = this.createOptionsEntity({
        title: "Update your chat",
        closeButtonText: "Close",
        onOk: (entity) => {
          this.setupKeyboardForChat();
          this.el.removeChild(entity);
          this.el.emit("settings-updated", {
            firstName: this.firstName,
            lastName: this.lastName,
          });
        },
        onCancel: (entity) => {
          this.el.removeChild(entity);
        },
        height: headerSettingsHeight,
        width: this.headerWidth,
        position: `${
          -this.width / 2 + this.headerWidth / 2
        } ${this.getRelativeY(
          -this.height / 2 - headerSettingsHeight
        )} ${this.getRelativeZ(0.01)}`,
      });

      this.el.appendChild(this.headerSettings);
    });

    this.el.appendChild(this.header);

    // Create a scrollbar
    this.scrollBar = document.createElement("a-plane");
    this.scrollBar.classList.add("clickable");
    this.scrollBar.setAttribute("height", this.height);
    this.scrollBar.setAttribute("width", this.scrollBarWidth);
    this.scrollBar.setAttribute(
      "position",
      `${this.width / 2 + this.scrollBarWidth / 2} ${this.chatPosition.y} ${
        this.chatPosition.z
      }`
    );
    this.scrollBar.setAttribute("color", this.secondaryColor);

    // Create a scroll thumb
    this.scrollThumb = document.createElement("a-plane");
    this.scrollThumb.setAttribute("height", 0);
    this.scrollThumb.setAttribute("width", this.scrollBarWidth);
    this.scrollThumb.setAttribute("position", `0 0 0.001`);
    this.scrollThumb.setAttribute("color", this.scrollBarThumbColor);
    this.scrollBar.appendChild(this.scrollThumb);

    this.el.appendChild(this.scrollBar);

    // Create chat input
    this.chatInput = document.createElement("a-plane");
    this.chatInput.classList.add("clickable");
    this.chatInput.setAttribute("height", this.chatHeight);
    this.chatInput.setAttribute("width", this.width);
    this.chatInput.setAttribute(
      "position",
      `0 ${-this.height / 2 - this.chatHeight / 2 + this.chatPosition.y} ${
        this.chatPosition.z
      }`
    );
    this.chatInput.setAttribute("color", this.primaryColor);
    this.chatInputTextId = "chat-input";
    this.chatInput.addEventListener("click", () => {
      this.setupKeyboardForChat();
    });

    const chatInputTextForm = document.createElement("a-entity");
    chatInputTextForm.setAttribute("form", "");

    this.chatInputText = document.createElement("a-text");
    const inputTextPlaceholder = "Type your message here...";
    this.chatInputText.setAttribute("form-field", {
      placeholder: inputTextPlaceholder,
      required: true,
    });
    this.chatInputText.setAttribute("id", this.chatInputTextId);
    this.chatInputText.setAttribute("color", "#FFF");
    this.chatInputText.setAttribute("width", this.width);
    this.chatInputText.setAttribute("align", "center");
    this.chatInputText.setAttribute("position", `0 0 0`);
    chatInputTextForm.appendChild(this.chatInputText);

    this.chatInput.appendChild(chatInputTextForm);

    this.el.appendChild(this.chatInput);

    // Create send message button
    this.sendMessageButton = document.createElement("a-plane");
    this.sendMessageButton.classList.add("clickable");
    this.sendMessageButton.setAttribute("height", this.chatHeight);
    this.sendMessageButton.setAttribute("width", this.scrollBarWidth);
    this.sendMessageButton.setAttribute(
      "position",
      `${this.width / 2 + this.scrollBarWidth / 2} ${
        -this.height / 2 - this.chatHeight / 2 + this.chatPosition.y
      } ${this.chatPosition.z}`
    );
    this.sendMessageButton.setAttribute("color", this.secondaryColor);
    this.sendMessageButton.addEventListener("click", () => {
      if (!this.keyboard) {
        return;
      }

      const keyboard = this.keyboard.components["keyboard"];

      keyboard.onEnterPressed();
    });

    this.sendMessageButtonImage = document.createElement("a-image");
    this.sendMessageButtonImage.setAttribute("src", "#icon-chat-send");
    this.sendMessageButtonImage.setAttribute("width", this.scrollBarWidth / 2);
    this.sendMessageButtonImage.setAttribute("height", this.scrollBarWidth / 2);
    this.sendMessageButtonImage.setAttribute("align", "center");
    this.sendMessageButtonImage.setAttribute("position", `0 0 0.02`);
    this.sendMessageButton.appendChild(this.sendMessageButtonImage);

    this.el.appendChild(this.sendMessageButton);

    // this.render();

    // this.updateScrollThumbHeight({
    //   scrollToBottom: true,
    // });

    // Listeners
    const controller = document.querySelector("#right-hand");
    controller.addEventListener("triggerdown", () => {
      const raycaster = controller.components.raycaster;

      const intersection = raycaster.intersections[0];
      if (intersection && intersection.object.el === this.scrollBar) {
        this.isDraggable = true;
      }
    });

    controller.addEventListener("triggerup", () => {
      this.isDraggable = false;
    });
  },

  createOptionsEntity({
    formId,
    height,
    width,
    position,
    title,
    onOk,
    onCancel,
    closeButtonText,
  }) {
    const settingsEntity = document.createElement("a-plane");

    const headerSettingsInputsHeight = 0.3 * height;
    const headerSettingsButtonsHeight = 0.3 * height;

    settingsEntity.setAttribute("height", height);
    settingsEntity.setAttribute("width", width);
    settingsEntity.setAttribute("color", "#000");
    settingsEntity.setAttribute("position", position);
    settingsEntity.setAttribute(
      "custom-layout",
      "coordinate: y; type: space-between"
    );
    settingsEntity.setAttribute("material", "opacity: 0.7");

    const headerSettingsText = document.createElement("a-text");
    headerSettingsText.setAttribute("color", "#FFF");
    headerSettingsText.setAttribute("height", 0.1 * height);
    headerSettingsText.setAttribute("width", width);
    headerSettingsText.setAttribute("align", "center");
    headerSettingsText.setAttribute("value", title);
    settingsEntity.appendChild(headerSettingsText);

    const headerSettingsInputHeight = 0.1;
    const headerSettingsInputWidth = (6 / 7) * width;
    const headerSettingsButtonHeight = 0.1;
    const headerSettingsButtonWidth = headerSettingsInputWidth;

    const createInputForHeaderSettings = (id, text, placeholder, onClick) => {
      const headerSettingsInput = document.createElement("a-plane");
      headerSettingsInput.setAttribute("height", headerSettingsInputHeight);
      headerSettingsInput.setAttribute("width", headerSettingsInputWidth);
      headerSettingsInput.setAttribute("color", "#333");
      headerSettingsInput.setAttribute("position", `0 0 0.001`);
      headerSettingsInput.classList.add("clickable");
      headerSettingsInput.addEventListener("click", onClick);

      const headerSettingsInputText = document.createElement("a-text");
      headerSettingsInputText.setAttribute("id", id);
      headerSettingsInputText.setAttribute("form-field", {
        value: text,
        placeholder,
        maxLength: 128,
        required: true,
      });
      headerSettingsInputText.setAttribute("color", "#FFF");
      headerSettingsInputText.setAttribute("width", width);
      headerSettingsInputText.setAttribute("align", "center");
      headerSettingsInputText.setAttribute("position", `0 0 0.001`);
      headerSettingsInput.appendChild(headerSettingsInputText);

      return headerSettingsInput;
    };

    const createButtonForHeaderSettings = (
      id,
      text,
      color,
      disabled,
      onClick
    ) => {
      const headerSettingsButton = document.createElement("a-plane");
      headerSettingsButton.setAttribute("id", id);
      headerSettingsButton.addEventListener("click", onClick);

      if (!disabled) {
        headerSettingsButton.classList.add("clickable");
      }

      headerSettingsButton.setAttribute("height", headerSettingsButtonHeight);
      headerSettingsButton.setAttribute("width", headerSettingsButtonWidth);
      headerSettingsButton.setAttribute("color", color);
      headerSettingsButton.setAttribute("position", `0 0 0.001`);

      const headerSettingsButtonText = document.createElement("a-text");
      headerSettingsButtonText.setAttribute("value", text);
      headerSettingsButtonText.setAttribute("color", "#FFF");
      headerSettingsButtonText.setAttribute("width", width);
      headerSettingsButtonText.setAttribute("align", "center");
      headerSettingsButtonText.setAttribute("position", `0 0 0.001`);

      headerSettingsButton.appendChild(headerSettingsButtonText);

      return headerSettingsButton;
    };

    const firstNameHeaderSettingsInputId = "first-name-header-settings";
    const firstNameHeaderSettingsCurrentText = this.firstName || "";
    const firstNameHeaderSettingsPlaceholder = "First name...";

    const lastNameHeaderSettingsInputId = "last-name-header-settings";
    const lastNameHeaderSettingsCurrentText = this.lastName || "";
    const lastNameHeaderSettingsPlaceholder = "Last name...";

    const disabled = !this.firstName || !this.lastName;

    const color = disabled ? this.disabledColor : this.primaryColor;
    const okButton = createButtonForHeaderSettings(
      "ok-button-header-settings",
      "OK",
      color,
      disabled,
      () => {
        const firstNameHeaderSettingsInput = document.getElementById(
          firstNameHeaderSettingsInputId
        ).components["form-field"];

        const lastNameHeaderSettingsInput = document.getElementById(
          lastNameHeaderSettingsInputId
        ).components["form-field"];

        this.firstName = firstNameHeaderSettingsInput.getValue();
        this.lastName = lastNameHeaderSettingsInput.getValue();

        onOk?.(settingsEntity);
      }
    );
    const closeButton = createButtonForHeaderSettings(
      "close-button-header-settings",
      closeButtonText,
      this.primaryColor,
      false,
      () => {
        onCancel?.(settingsEntity);
      }
    );

    const headerSettingsInputs = document.createElement("a-entity");

    const setupKeyListener = () => {
      const formElement = document.getElementById(formId);
      const form = formElement.components["form"];

      formElement.addEventListener("formchange", () => {
        if (form.state.isValid) {
          okButton.setAttribute("color", this.primaryColor);
          okButton.classList.add("clickable");
        } else {
          okButton.setAttribute("color", this.disabledColor);
          okButton.classList.remove("clickable");
        }
      });
    };

    const firstNameHeaderSettingsInput = createInputForHeaderSettings(
      firstNameHeaderSettingsInputId,
      firstNameHeaderSettingsCurrentText,
      firstNameHeaderSettingsPlaceholder,
      () => {
        this.setupKeyboardFor(firstNameHeaderSettingsInputId);
        setupKeyListener();
      }
    );
    const lastNameHeaderSettingsInput = createInputForHeaderSettings(
      lastNameHeaderSettingsInputId,
      lastNameHeaderSettingsCurrentText,
      lastNameHeaderSettingsPlaceholder,
      () => {
        this.setupKeyboardFor(lastNameHeaderSettingsInputId);
        setupKeyListener();
      }
    );

    headerSettingsInputs.setAttribute("height", headerSettingsInputsHeight);

    headerSettingsInputs.setAttribute("id", formId);
    headerSettingsInputs.setAttribute("form", "");
    headerSettingsInputs.setAttribute("custom-layout", "coordinate: y;");
    headerSettingsInputs.appendChild(firstNameHeaderSettingsInput);
    headerSettingsInputs.appendChild(lastNameHeaderSettingsInput);

    const headerSettingsButtons = document.createElement("a-entity");
    headerSettingsButtons.setAttribute("height", headerSettingsButtonsHeight);
    headerSettingsButtons.setAttribute("custom-layout", "coordinate: y;");
    headerSettingsButtons.appendChild(okButton);
    headerSettingsButtons.appendChild(closeButton);

    settingsEntity.appendChild(headerSettingsInputs);
    settingsEntity.appendChild(headerSettingsButtons);

    return settingsEntity;
  },

  setupKeyboardForChat() {
    this.setupKeyboardFor(this.chatInputTextId, (input) => {
      this.chatInputText.components["form-field"].setValue("");

      this.el.emit("message-sent", {
        message: input,
      });
    });
  },

  setupKeyboardFor(inputElementId, onEnterPressed) {
    const isCurrentlyOpenForAnotherElement =
      this.keyboardCurrentlyFor !== inputElementId;

    const inputElement = document.getElementById(inputElementId);
    const field = inputElement.components["form-field"];

    const currentText = field.getValue();

    this.toggleKeyboard(isCurrentlyOpenForAnotherElement, currentText);

    this.keyboardCurrentlyFor = inputElementId;

    this.keyboard.addEventListener("key-pressed", (event) => {
      const input = event.detail.input;
      field.setValue(input);
    });

    this.keyboard.addEventListener("enter-pressed", (event) => {
      const input = event.detail.input;

      if (input === "") {
        return;
      }

      onEnterPressed?.(input);
    });
  },

  killKeyboard() {
    if (!this.keyboard) {
      return;
    }

    this.el.removeChild(this.keyboard);
    this.keyboard = null;
  },

  toggleKeyboard(recreate, initialText) {
    if (!recreate && this.keyboard) {
      this.killKeyboard();
      return;
    }

    if (recreate) {
      if (this.keyboard) {
        this.el.removeChild(this.keyboard);
        this.keyboard = null;
      }
    }

    this.keyboard = document.createElement("a-plane");
    this.keyboard.setAttribute("height", this.keyboardHeight);
    this.keyboard.setAttribute("width", this.keyboardWidth);
    this.keyboard.setAttribute("keyboard", "");
    this.keyboard.setAttribute(
      "position",
      `0 ${
        -this.height / 2 - this.keyboardHeight / 2 - 0.5 + this.chatPosition.y
      } ${this.chatPosition.z}`
    );

    this.keyboard.addEventListener("loaded", () => {
      if (initialText) {
        this.keyboard.components["keyboard"].setInput(initialText);
      }
    });

    this.el.appendChild(this.keyboard);
  },

  areAllMessagesRendered: function () {
    return this.chatContent.children.length >= this.numberOfDisplayableMessages;
  },

  isScrollThumbAtBottom: function () {
    const scrollThumbHeight = this.scrollThumb.getAttribute("height");
    const bottomPosition = (scrollThumbHeight - this.height) / 2;

    const currentThumbPosition = this.scrollThumb.object3D.position.y;

    return currentThumbPosition === bottomPosition;
  },

  updateScrollThumbHeight: function (
    settings = {
      scrollToBottom: false,
      updatePosition: true,
    }
  ) {
    const numberOfMessages = this.messages.length;

    if (numberOfMessages === 0) {
      return;
    }

    const newScrollThumbHeight =
      this.numberOfDisplayableMessages > numberOfMessages
        ? this.height
        : (this.numberOfDisplayableMessages / numberOfMessages) * this.height;
    const newBottomPosition = (newScrollThumbHeight - this.height) / 2;
    const newTopPosition = -newBottomPosition;

    // TODO: we need to find out how to update both things at once, because this way you can see it first modifying height then position
    if (settings.scrollToBottom) {
      this.scrollThumb.setAttribute("height", newScrollThumbHeight);

      // TODO: without this it doesn't work?
      setTimeout(() => {
        this.scrollThumb.object3D.position.set(
          this.scrollThumb.object3D.position.x,
          newBottomPosition,
          0.001
        );
      }, 0);

      this.thumbProgress = 1;
    }

    if (settings.updatePosition) {
      const scrollThumbHeight = this.scrollThumb.getAttribute("height");
      const bottomPosition = (scrollThumbHeight - this.height) / 2;
      const currentThumbPosition = this.scrollThumb.object3D.position.y;

      if (currentThumbPosition === bottomPosition) {
        return this.updateScrollThumbHeight({
          scrollToBottom: true,
        });
      }

      const differenceInHeight =
        this.scrollThumb.getAttribute("height") - newScrollThumbHeight;
      const upDifference = differenceInHeight / 2;

      this.scrollThumb.setAttribute("height", newScrollThumbHeight);
      // TODO: without this it doesn't work??

      setTimeout(() => {
        this.scrollThumb.setAttribute(
          "position",
          `0 ${this.scrollThumb.object3D.position.y + upDifference} 0.001`
        );
      }, 0);

      this.thumbProgress =
        bottomPosition === 0
          ? 1
          : Math.abs(
              (this.scrollThumb.object3D.position.y - newTopPosition) /
                (2 * bottomPosition)
            );
    }
  },

  renderMessage: function (message, y) {
    const planeZIndex = 0.001;

    const messagePlane = document.createElement("a-plane");
    messagePlane.setAttribute("height", this.messageHeight);
    messagePlane.setAttribute("width", this.messageWidth);
    messagePlane.setAttribute("color", "#444");
    messagePlane.setAttribute("position", `0 ${y} ${planeZIndex}`);

    // Add avatar (image) on the left
    const avatarImage = document.createElement("a-image");
    avatarImage.setAttribute("src", message.avatar); // Avatar URL
    // TODO: the ratios
    avatarImage.setAttribute("height", this.messageHeight);
    avatarImage.setAttribute("width", this.messageHeight);
    avatarImage.setAttribute(
      "position",
      `${-this.messageWidth / 2 + this.messageHeight / 2} 0 ${planeZIndex * 2}`
    ); // Position the avatar on the left

    // Add text to the right of the avatar
    const distanceBetweenAvatarAndText = 0.05;

    const messageText = document.createElement("a-text");
    messageText.setAttribute("value", `${message.username}: ${message.text}`);
    messageText.setAttribute("color", "#FFF");
    messageText.setAttribute(
      "width",
      this.messageWidth -
        avatarImage.getAttribute("width") -
        distanceBetweenAvatarAndText
    ); // Adjust to fit the message area
    messageText.setAttribute("align", "left");
    messageText.setAttribute(
      "position",
      `-${messageText.getAttribute("width") - this.messageWidth / 2} 0 ${
        planeZIndex * 2
      }`
    ); // Text slightly to the right of the avatar

    // Append avatar and text to the message plane
    messagePlane.appendChild(avatarImage);
    messagePlane.appendChild(messageText);

    // Append the message plane to the chat content
    return messagePlane;
  },

  createNewMessage: function (message) {
    this.messages.push(message);

    const isScrollAtBottom = this.isScrollThumbAtBottom();
    const areAllMessagesRendered = this.areAllMessagesRendered();

    if (!areAllMessagesRendered || isScrollAtBottom) {
      this.render({
        force: true,
      });
    }

    this.updateScrollThumbHeight({
      updatePosition: true,
    });
  },

  setMessages: function (messages) {
    this.messages = [...messages];

    this.render({
      force: true,
    });

    this.updateScrollThumbHeight({
      scrollToBottom: true,
    });
  },

  getPositionForNthMessage: function (n) {
    return (
      this.firstMessagePosition -
      (this.messageHeight + this.messageSpacing) * (n - 1)
    );
  },

  // if you have less than displayableMessages already rendered, and force = true, you are still going to render them all again...
  render: function (
    settings = {
      force: false,
    }
  ) {
    const messagesToDisplayStartIndex = Math.max(
      Math.floor(
        this.thumbProgress * this.messages.length -
          this.numberOfDisplayableMessages
      ),
      0
    );

    if (
      !settings.force &&
      this.lastFirstMessageIndex === messagesToDisplayStartIndex
    ) {
      return;
    }

    this.lastFirstMessageIndex = messagesToDisplayStartIndex;

    this.chatContent.innerHTML = "";

    const currentNumberOfDisplayableMessages = Math.min(
      this.numberOfDisplayableMessages,
      this.messages.length
    );

    const messagesToDisplayEndIndex =
      messagesToDisplayStartIndex + currentNumberOfDisplayableMessages;

    const fragment = document.createDocumentFragment();

    for (
      let i = messagesToDisplayStartIndex;
      i < messagesToDisplayEndIndex;
      i++
    ) {
      const message = this.messages[i];
      const y = this.getPositionForNthMessage(
        i - messagesToDisplayStartIndex + 1
      );
      const plane = this.renderMessage(message, y);
      fragment.appendChild(plane);
    }

    this.chatContent.appendChild(fragment);
  },

  tick: function () {
    const controller = document.querySelector("#right-hand");

    if (!controller) return;

    if (!this.isDraggable) {
      return;
    }

    const raycaster = controller.components.raycaster;

    const intersection = raycaster.intersections[0];

    if (intersection) {
      const localPoint = this.scrollThumb.object3D.parent.worldToLocal(
        intersection.point.clone()
      );

      const bottomPosition =
        (this.scrollThumb.getAttribute("height") - this.height) / 2;
      const topPosition = -bottomPosition;

      const currentY = localPoint.y;
      const newThumbY =
        currentY > topPosition
          ? topPosition
          : currentY < bottomPosition
          ? bottomPosition
          : currentY;

      this.scrollThumb.object3D.position.set(
        this.scrollThumb.object3D.position.x,
        newThumbY,
        this.scrollThumb.object3D.position.z
      );

      this.thumbProgress =
        bottomPosition === 0
          ? 1
          : Math.abs((newThumbY - topPosition) / (2 * bottomPosition));

      this.render({
        force: false,
      });
    }
  },
};
